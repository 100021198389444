import gql from 'graphql-tag'

export default gql`
query getHotelStaffByUserRole($userRole: String! $nextToken: String){
  getHotelStaffByUserRole(userRole: $userRole nextToken:$nextToken){
    items{
      id
      siteId
      username
      userRole
      emailId
      mobile
      name
      assignedTables
      enabled
      createdAt
      updatedAt
      isCaptain
    }
    nextToken
  }
}
`