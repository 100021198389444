import gql from 'graphql-tag'
export default gql`
query{
    getServiceTags{
      id
      siteId
      tagId
      tagName
      status
      displayOrder
      tagOptions{
        name
        icon{
          bucket
          region
          key
        }
      }
    }
  }`