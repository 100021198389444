import React, {Component, Fragment } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
// import CustomeRole from '../customRoleApp/customRole'
// import MyTables from '../WaiterRole/tables'
// import Station from '../kitchenRole/selectStation'
import Orders from '../kitchenRole/orders'
import AllStation from "../kitchenAllStation/allStation";
import AllStationServices from '../kitchenAllStation/allstationServices'
import OrderDisplay from '../kitchenAllStation/orderDisplay'
import GetChefLogin from '../kitchenRole/getUserNameChef'
import orderDisplay from "../kitchenAllStation/orderDisplay";
const RoutesComp = (props) =>{
    // console.log('props:::routes kitchen',props.userData.siteId)
        return(
            // <Router basename="/staff/">
                <Router >
                <Fragment>
                  
                    {/* <Route exact path="/" component={AllStation} /> */}
                    <Route path="/" component={() => <AllStation siteId={props.userData.siteId} />} /> 
                    {/* <Route  exact path="/waiter" component={MyTables} /> */}
                    {/* <Route  exact path="/allStationServices" component={AllStationServices} /> */}
                    <Route path="/orderDisplay" component={orderDisplay} /> 
                </Fragment>
            </Router>
        )
    }
    
    export default RoutesComp