import React from 'react';

export const itemsData = {
    curr:'',
    productCount:''
}

export const SelectCurrencyContext = React.createContext({
    itemsData
});
