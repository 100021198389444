import React from 'react'
import { Row, Col, Button, Spin,Select } from "antd";
import { Auth } from "aws-amplify";
import { withRouter, Link } from "react-router-dom";
import App from '../../../src/components/kitchenRoleApp/kitchenRoleMain'
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus'
import getHotelStations from '../../queries/getHotelStations'
import AllStationSer from './allstationServices'

import tableOrderCreated from '../../subscription/tableOrderCreated'
import tableOrderUpdated from '../../subscription/tableOrderUpdated'
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2'

import updateTableOrderItemStatus from '../../mutations/updateTableOrderItemStatus'

// import { withApollo } from "react-apollo";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { assertObjectType, NoSchemaIntrospectionCustomRule } from 'graphql';
const FormItem = Form.Item;
const stations = ['Kitchen', 'Bar']

class AllStation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            station: '',
            siteId:'',
            userId:'',
            loadingStation:false,
            stations:[],
            stationWiseData:null,
            loading:false,
            serviceId: null,
            subOrderId:null
        }
        this.UserId()
    }

    UserId = async () => {
        await Auth.currentUserInfo()
        .then((data) => {
            this.setState({
                userId:data.username,
                siteId: data.attributes['custom:siteId']
            })
        })
        .catch(err => console.log(err));
    }

    componentDidMount=()=>{
        this.filterData()
    }

    filterData=async()=>{
        var arr = []
        let stationData = await this.getStation()
        if(stationData && stationData.length>0){
         stationData.map(async(s)=>{
             let allOrders = await this.getSiteOrder(s.typeValue)
             allOrders && allOrders.orderList.length> 0 && arr.push(allOrders)
        // console.log('stationWiseData:::::',arr)

             this.setState({
                 stationWiseData:arr,
                //  loading:false
                })
        
             
         })
        }    
        this.tableOrderCreatedSubscription()
        this.tableOrderUpdatedV2SubscriptionCall(stationData)
     
    }
    liveDatafilter=async(data)=>{
        let arr = []
     let flag = await this.createData(data)
     let alldata = this.state.stationWiseData
    //  console.log('alldata::::::',alldata,flag)
     if(alldata.length>0){
     try{
         alldata &&  alldata.length>0 && alldata.map((obj1,i)=>{
            if( flag.length>0 ){
             flag.map((obj2,j)=>{
                let find = alldata.findIndex((st)=>st.station==obj2.station)
                let findObj = alldata.find((st)=>st.station==obj2.station)
                if(find>=0){
                   obj1.orderList.map((order,k)=>{
                    if(order.orderId==obj2.order[0].orderId){
                    // alldata[i].orderList[k]==obj2.order
                    findObj.orderList=obj2.order
                    // console.log('kkkkkkkkkkkkkkkkkkkkkkkkkk',alldata[i].orderList[k])
                    }else{
                        console.log('order not found::::',obj2.order[0])
                        findObj.orderList.push(obj2.order[0])
                    }
                   }) 
                }else{
                    let ob={
                        station:obj2.station,
                        orderList:obj2.order
                    }
                    alldata.push(ob)
                }
              })
            }else{
             //// if not order
            }
           })
        }catch(err){
            console.log('errrrrrrrrrrrrrrrr',err)
        }
    }else{
        try{
       if(flag.length>0){
     
        flag.map((f)=>{
            let obj={}
            obj['station']=f.station
            obj['orderList']=f.order
            alldata.push(obj)
        })
     
       }
       else{
        let obj2={}
        obj2['station']=null
        obj2['orderList']=null
        alldata.push(obj2)
       }
     
    }catch(err){
        console.log('errrrrrrrrrrrrrr',err)
    }
    }
        // console.log('finaldataaaaaaaaaaaaaaaa',alldata)
     this.setState({
        stationWiseData:alldata
     })
    }


    tableOrderUpdatedV2SubscriptionCall = (allStations) =>{
        let that = this

        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables:{
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({data}) => {
             console.log('data orderupdated::::',data)
            //  this.filterData()
            //  this.liveDatafilter(data.tableOrderUpdatedV2)
        this.filterDataNew(data.tableOrderUpdatedV2,allStations)
           

            //  this.filterData()
            //   let item = data.tableOrderUpdatedV2
            //   let orders = [];
            //   orders = item.orderList.map((x)=>{
            //       return x.services
            //   })
            // let services = orders[0]
            //   let newOrder = services.find((x)=>x.status == 'NEW' && x.station == `${that.state.station}`)
            //   let itemPresent = that.state.dataSource.find((x)=>x.id == item.id)
            //   if(newOrder && that.state.updateflag==false){
            //     console.log('play audio')
            //       const audioEl = document.getElementsByClassName("audio-element")[0]
            //       audioEl.play()
            // }   
      

       
                                                                            
            // if(newOrder && that.state.updateflag==false){
            //     if(that.state.kitchenStatus != 'NEW' && (data.tableOrderUpdatedV2.orderFrom != 'online-menu' 
            //     || (data.tableOrderUpdatedV2.orderFrom == 'online-menu' && data.tableOrderUpdatedV2.orderStatus != 'NEW'))){
            //         message.warning('New order received')
            //     }
               
            //   if(that.state.kitchenStatus == 'NEW' && (data.tableOrderUpdatedV2.orderFrom != 'online-menu' 
            //     || (data.tableOrderUpdatedV2.orderFrom == 'online-menu' && data.tableOrderUpdatedV2.orderStatus != 'NEW'))){
               
            //       that.updateOrderItems(data.tableOrderUpdatedV2, that.state.kitchenStatus)
            //   }
            // }
            // that.setState({
            //     updateflag:false
            // })
           
             
         
           
            },
            error(err) { console.error('err', err); },
        });
    }

    filterDataNew = (data, stations) => {
        let newData = { ...data }
     
        let finalArr = []
        stations.map((st) => {
            let orderData =  JSON.parse(JSON.stringify(newData))
            orderData.orderList.map((o) => {
                let arr = []
                o.services.map((s) => {
                    // console.log('/////',s.station,st.typeValue)
                    if(s.station == st.typeValue && (s.status=='NEW' || s.status=='IN_PROCESS')) {
                        arr.push(s)
                    }
                })
                o.services = arr
            })
            let obj = {
                station: st.typeValue,
                orderList: orderData
            }
            finalArr.push(obj)
        })
let stData = this.state.stationWiseData

finalArr.map((f2)=>{
 let newOrderList = f2.orderList.orderList.filter((or2)=>or2.services.length> 0)
 f2.orderList.orderList=newOrderList
})

// finalArr = finalArr.filter((f3)=>f3.orderList.orderList.length>0)
        let stationsData =  JSON.parse(JSON.stringify(stData))
        if(stationsData.length>0){
         stationsData.map((st,i)=>{
            finalArr.map((f)=>{
          let find = stData.findIndex((f1)=>f1.station==f.station)
                if(find>=0){
                    st.orderList.map((or,j)=>{
                        let find1 = st.orderList.findIndex((or)=>or.id==f.orderList.id)
                        if(find1>=0){
                            if(or.id == f.orderList.id && st.station==f.station){
                            stationsData[i].orderList[j]=f.orderList
                            }
                        }else{
                            //order not found
                            if(st.station == f.station){
                            stationsData[i].orderList.push(f.orderList)
                            }
                        }
                    })
                }else{
                    //station not found in stationwiseData
                    let arr = []
    arr.push(f.orderList)
    let obj ={
        station:f.station,
        orderList:arr
    }
    stationsData.push(obj)
                }
            })
         })

        //  this.setState({
        //     stationWiseData:stationsData
        // })
        this.getsortStationData(stationsData)
        }else{
            //no order in kitchen
let arr2 = []
finalArr.map((f)=>{
    let arr = []
    arr.push(f.orderList)
    let obj ={
        station:f.station,
        orderList:arr
    }
arr2.push(obj)
})
// this.setState({
//     stationWiseData:arr2
// }) 
this.getsortStationData(arr2)
}

    }


    getsortStationData=(data)=>{
data.map((d)=>{
  let k = d.orderList.filter((d)=>d.orderList.length>0)
d.orderList = k
    })
data = data.filter((d2)=>d2.orderList.length>0)
this.setState({
    stationWiseData:data
})
    }

    tableOrderCreatedSubscription = () =>{
        let that = this;
        this.subscription = this.props.client.subscribe({
            query: tableOrderCreated,
            variables:{
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({data}) => {
               console.log('data:::::::tableordercreated ::',data)
                // if(data.tableOrderCreated.orderList.length > 0 && (data.tableOrderCreated.orderFrom != 'online-menu' || (data.tableOrderCreated.orderFrom == 'online-menu' && data.tableOrderCreated.orderStatus != 'NEW'))){
                //     that.createDataV2(data.tableOrderCreated, that.state.kitchenStatus)
                //     that.getOrderItemsOnSubscribe([data.tableOrderCreated])
                // }
             
            },
            error(err) { console.error('err', err); },
        });
    }


    loadingStation = () => {
        this.setState({
            loadingStation: !this.state.loadingStation
        })
    }

    getSiteOrder = (st) => {
        return new Promise((resolve,reject)=>{
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'no-cache',
            variables: {
                station: st,
                status: ['NEW','IN_PROCESS','PREPARED'],
                skip: 0,
                limit: 50

            }
        })
            .then(({ data }) => {
                if (data.getSiteOrderByItemStatus != null) {
                    let allOrders = data.getSiteOrderByItemStatus
                this.createDataStation(allOrders,st,resolve)
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
        })
    }
    createData=(val)=>{
        return new Promise((resolve,reject)=>{

     
        let arr = []
      
            let obj = {}
            let totalCount = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['updatedAt'] = val.updatedAt
                obj['grandTotal'] = val.grandTotal
                obj['timestamp'] = val.timestamp
                // obj['primaryWaiter'] = val.primaryWaiter
                obj['orderFrom'] = val.orderFrom
                obj['subOrderFrom'] = val.subOrderFrom
                let arr2 = []
              
                val.orderList.map((k) => {
                    let obj2 = {}
                    
                    let arr3 = []
                    if(k.services &&  k.services.length> 0){
                     k.services.map((s) => {
                        if(s.status!='COMPLETED' && s.status!='CANCELLED'){
                        let obj3 = {...s}
                        totalCount = totalCount + s.qty
                    
                        // obj3['serviceId'] = s.serviceId
                        // obj3['serviceRate'] = s.serviceRate
                        // obj3['serviceName'] = s.serviceName
                        // obj3['qty'] = s.qty
                        // obj3['status'] = s.status
                        // obj3['instruction'] = s.instruction
                        // obj3['station'] = s.station
                        // obj3['tax'] = s.tax
                        // obj3['isUrgent'] = s.isUrgent
                        arr3.push(obj3)
                        }
                    
                    })
                    if (arr3.length > 0) {
                        obj2['subOrderId'] = k.subOrderId
                    obj2['services'] = arr3
                    arr2.push(obj2)
                    }
                  }
  
                })
                // if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalCount'] = totalCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr.push(obj)
            
  
//   let newObj =  obj.orderList.length> 0 && obj.orderList.filter((sub)=>sub.services.length>0)
  arr.orderList=obj
let obj1=arr[0]
let arrSt=[]
console.log('obj11111',obj1)
try{
    if(obj1.orderList.length>0){
obj1.orderList.length>0 && obj1.orderList.map((sub)=>{
    sub.services.map((ser)=>{
        if(arrSt.length==0){
            let ob={
            station:ser.station,
            order:obj1
            }
            arrSt.push(ob)
         
        }else{
            let k = arrSt.findIndex((st)=>st.station==ser.station)
            console.log('kkkkkk',k)
            if(k<0){
                let ob={
                    station:ser.station,
                    order:obj1
                    }
                    arrSt.push(ob)    
            }
        }
    })
})
    }
}catch(err){
    console.log('errrrrrrrrr',err)
}
console.log('arrst:::::',arrSt)
try{
    let arr=[]
   arrSt.map(async(obj)=>{
    let answer = await this.createAns(obj.order,obj.station)
    console.log('answer::',answer)
    if(answer){
        let ob={
            station:obj.station,
            order:answer
        }
        arr.push(ob)


    }
   })
   resolve(arr)
}catch(err){
}
console.log('arrSt222222222222',arrSt)
            })
      }

      createAns=(val,st)=>{
        return new Promise((resolve,reject)=>{
        let arr = []
      try{
            let obj = {}
            let totalCount = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['updatedAt'] = val.updatedAt
                obj['grandTotal'] = val.grandTotal
                obj['timestamp'] = val.timestamp
                // obj['primaryWaiter'] = val.primaryWaiter
                obj['orderFrom'] = val.orderFrom
                obj['subOrderFrom'] = val.subOrderFrom
                let arr2 = []
              
                val.orderList && val.orderList.length>0 && val.orderList.map((k) => {
                    let obj2 = {}
                    obj2['subOrderId'] = k.subOrderId
                    let arr3 = []
                    if(k.services &&  k.services.length> 0){
                     k.services.map((s) => {
                        if(s.station==st){
                        let obj3 = {...s}
                        totalCount = totalCount + s.qty
                    
                        // obj3['serviceId'] = s.serviceId
                        // obj3['serviceRate'] = s.serviceRate
                        // obj3['serviceName'] = s.serviceName
                        // obj3['qty'] = s.qty
                        // obj3['status'] = s.status
                        // obj3['instruction'] = s.instruction
                        // obj3['station'] = s.station
                        // obj3['tax'] = s.tax
                        // obj3['isUrgent'] = s.isUrgent
                        arr3.push(obj3)
                        }
                    
                    })
                    // if (arr3.length > 0) {
                    obj2['services'] = arr3
                    arr2.push(obj2)
                    // }
                  }
  
                })
                // if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalCount'] = totalCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr.push(obj)
            
  resolve(arr)
            }catch(err){
                console.log('err222234',err)
            }
  
            })
      }
  
    createDataStation=(data,st,resolve)=>{
      let arr = []
      try{
      data.map((val, i) => {
          let obj = {}
          let totalCount = 0
              obj['id'] = val.id
              obj['orderId'] = val.orderId
              obj['name'] = val.name
              obj['tableNo'] = val.tableNo
              obj['updatedAt'] = val.updatedAt
              obj['grandTotal'] = val.grandTotal
              obj['timestamp'] = val.timestamp
              // obj['primaryWaiter'] = val.primaryWaiter
              obj['orderFrom'] = val.orderFrom
              obj['subOrderFrom'] = val.subOrderFrom
              let arr2 = []
            
              val.orderList.map((k) => {
                  let obj2 = {}
                  obj2['subOrderId'] = k.subOrderId
                  let arr3 = []
                  if(k.services &&  k.services.length> 0){
                   k.services.map((s) => {
                      let obj3 = {}
                      totalCount = totalCount + s.qty
                  
                      obj3['serviceId'] = s.serviceId
                      obj3['serviceRate'] = s.serviceRate
                      obj3['serviceName'] = s.serviceName
                      obj3['qty'] = s.qty
                      obj3['status'] = s.status
                      obj3['instruction'] = s.instruction
                      obj3['station'] = s.station
                      obj3['tax'] = s.tax
                      obj3['isUrgent'] = s.isUrgent
                      obj3['attachedCat'] = s.attachedCat
                      arr3.push(obj3)
                  
                  })
                  // if (arr3.length > 0) {
                  obj2['services'] = arr3
                  arr2.push(obj2)
                  // }
                }

              })
              // if (arr2.length > 0) {
              obj['orderList'] = arr2
              obj['totalCount'] = totalCount
              obj['tableOrderStatus'] = val.tableOrderStatus
              arr.push(obj)
          

          // }
      })
    }catch(err){
        console.log('errrrrrrrrr',err)
    }
              let arr2 = arr.filter((ser)=>ser.orderList.length!=0)
            let obj={
                station:st,
                orderList:arr2,
            }
            resolve(obj)
    }


    getStation = () => {
        return new Promise((resolve,reject)=>{
        this.props.client.query({
            query: getHotelStations,
            fetchPolicy: 'no-cache',
          
        })
            .then(({ data }) => {
            if(data.getHotelStations){
               resolve(data.getHotelStations)
             
            }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
        })
    }


    enterLoading = () => {
        this.setState({
            loading: true
        })
    }


    AcceptPrepared = (id, subid, sid, status) => {
        // console.log('Accept prepared::')
        this.enterLoading()
        this.setState({
            serviceId: sid,
            subOrderId:subid
        }, () => {
            this.updateStatus(id, subid, sid, status)
        }
        )
    }

    updateStatus = (id, subid, sid, status) => {
      
        this.enterLoading()
        this.props.client.mutate({
            mutation: updateTableOrderItemStatus,
            variables: {
                id: id,
                subOrderId: subid,
                serviceId: sid,
                status: status == 'NEW' ? 'IN_PROCESS' : 'PREPARED'
            }
        }).then(({ data }) => {
            // console.log("updateStatus",data)
            this.setState({
                loading:false,
                serviceId:null,
                subOrderId:null
            })
            message.success("Updated  Successfully")
            // let data1 = this.state.dataSource
            // console.log('data1::,data',data1)
            // data1.map((s, m) => {
            //     s.orderList.map((k, j) => {
            //         for (var i = 0; i < k.services.length; i++) {
            //             if (k.services[i].serviceId == sid && k.subOrderId==subid) {
            //                 k.services.splice(i, 1)
            //                 s['totalItems'] = s.totalItems - 1
            //             }
            //             // k.services.length == 0 && s.orderList.splice(j, 1)
            //         }
            //         // s.orderList.length == 0 && data1.splice(m, 1)
            //     })

            // })
            // this.getSiteOrder(this.state.kitchenStatus)
            // console.log("data befor Update",data1)
            // this.setState({
            //     // dataSource: data1,
            //     loading: false,
            //     serviceId:null,
            //     subOrderId:null

            //     // updateflag:true
            // })

        }).catch(err => {
            console.log('errrr', err)
        })
    }


    render() {
        const {stationWiseData,loading,serviceId,subOrderId} = this.state
        return (
            <App>
            {stationWiseData && stationWiseData.length> 0  ?
          <AllStationSer
          AcceptPrepared={this.AcceptPrepared}
          loading={this.state.loading}
          serviceId={this.state.serviceId}
          subOrderId={this.state.subOrderId}
          stationWiseData={stationWiseData}/>
          :
        //   <Spin/>
        null
            }
            </App>
        )
    }
}
const wrappAllStation = Form.create({ name: "JBooking_form" })(AllStation);

export default withRouter(withApollo(wrappAllStation))