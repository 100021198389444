import React, { Component } from 'react';
import App from '../../../src/components/kitchenRoleApp/kitchenRoleMain'
import { withRouter, Link } from "react-router-dom";
import { graphql, withApollo } from '@apollo/client/react/hoc';

class OrderDisplay extends Component {
    render() {
        console.log('order Display')
        return (
           <></>
        );
    }
}

export default withRouter(withApollo(OrderDisplay))
