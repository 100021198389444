import gql from 'graphql-tag'

export default gql`
query batchGetDiningTables( $tableIds: [String!]!){
    batchGetDiningTables(tableIds: $tableIds){
      id
      siteId
      typeValue
      tableName
      capacity
      attachedMenu
      tableNo
      enabled
      tableStatus
      blockedBy
      createdAt
      updatedAt
      attachedArea
    }
  }
`
//  {
//        "tableIds": ["table::3","table::1"]
//      }
    