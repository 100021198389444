import React from 'react'
import unslugify from "unslugify";
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2'
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus'
import { Card, Row, Col, Button, List, Select, Spin, Table, Checkbox, Dropdown, Menu, Radio } from "antd";
import { Tabs } from 'antd';
import { withRouter } from 'react-router';
import moment from 'moment'
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Option } = Select;


class serveItems extends React.Component {
    constructor() {
        super();
        this.state = {
            dataSource: null,
            platform: null,
            createDataSpin: false
        };
    }

    componentDidMount = () => {

        

        if (window.screen.width >= 700) {
            //   1280
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }
         
        this.setState({ createDataSpin: true })
        
        this.getSiteOrder()

        this.tableOrderUpdatedV2SubscriptionCall()

    }

    getSiteOrder = () => {
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'network-only',
            variables: {
                station: this.props.station,
                // station:'kitchen',
                status: ['PREPARED','CANCELLED','COMPLETED','SHIFTED'],
                skip: 0,
                limit: 1000
                // tableNo:'1'

            }
        })
            .then(({ data }) => {
                //   console.log("data",data)
                if (data.getSiteOrderByItemStatus != null && data.getSiteOrderByItemStatus.length > 0) {
                  
                    //  console.log("Query::", data.getSiteOrderByItemStatus)
                    let Data = JSON.parse(JSON.stringify(data.getSiteOrderByItemStatus))

                    let copy = Data && Data.length > 0 && Data.filter((order) => {
                        let flag = false;
                        order.orderList.map((item) => {
                            if (item.services.length > 0) {
                                flag = true;
                            }
                        })

                        if (flag) {
                            return order
                        }
                    })

                    this.createData(copy)
                    // console.log("Copy:::", copy)



                    //this.setState({dataSource:copy})
                }else{
                    this.setState({
                        createDataSpin: false,
                        dataSource: []
                    })
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: query error: ${JSON.stringify(err, null, 2)}`,err)
            })
    }

    tableOrderUpdatedV2SubscriptionCall = () => {
        let that = this
        let retryAttempts = 0; 
        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables: {
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({ data }) => {
                // console.log("Dtaa::", data);
                retryAttempts = 0;
               // this.createDataV2(data.tableOrderUpdatedV2, null)
               this.createOnUpdate(data.tableOrderUpdatedV2, null)

            },
            error: (err) => {
                retryAttempts++;
                console.log('tableOrderUpdatedV2  error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  // Calculate the exponential backoff delay (adjust as needed)
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderUpdatedV2SubscriptionCall, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

    createData = (data) => {
        // console.log('data:createData:::', data)

        let finalData = this.filterBYKOtID(data)

        // console.log('finalData:::', finalData)

        let arr = []
        finalData.map((val, i) => {
            if (val.orderStatus !== 'DISPATCHED') {
                let obj = {}
                let count = 0
                let totalCount = 0
                let newCount = 0
                let inProcessCount = 0
                let Prepared = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['kotId'] = val.kotId
                obj['tokenId'] = val.tokenId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['updatedAt'] = val.updatedAt
                obj['timestamp'] = val.timestamp
                obj['orderFrom'] = val.orderFrom
                obj['orderSource'] = val.orderSource
                obj['subOrderFrom'] = val.subOrderFrom
                obj['newOrderFlag'] = val.newOrderFlag
                let arr2 = []
                val.orderList.map((k) => {
                    let obj2 = {}
                    obj2['subOrderId'] = k.subOrderId
                    let arr3 = []
                    k.services.map((s) => {
                        let obj3 = { ...s }
                        if (s.station == this.props.station) {
                            count++
                            totalCount = totalCount + s.qty
                            // obj3['serviceId'] = s.serviceId
                            // obj3['serviceName'] = s.serviceName
                            // obj3['qty'] = s.qty
                            // obj3['status'] = s.status
                            // obj3['station'] = s.station
                            // obj3['isUrgent'] = s.isUrgent
                            // obj3['instruction'] = s.instruction
                            arr3.push(obj3)

                        }
                        if (s.station == this.props.station && s.status == 'NEW') {
                            // newCount = newCount + 1
                        }
                        if (s.station == this.props.station && s.status == 'IN_PROCESS') {
                            inProcessCount = inProcessCount + 1
                        }
                        if (s.station == this.props.station && s.status == 'PREPARED') {
                            Prepared = Prepared + 1
                        }
                    })
                    if (arr3.length > 0) {
                        obj2['services'] = arr3
                        arr2.push(obj2)
                    }

                })
                if (arr2.length > 0) {
                    obj['orderList'] = arr2
                    obj['totalItems'] = count
                    obj['newCount'] = newCount
                    obj['inProcessCount'] = inProcessCount
                    obj['Prepared'] = Prepared
                    obj['totalCount'] = totalCount
                    obj['tableOrderStatus'] = val.tableOrderStatus
                    arr.push(obj)
                }
            }
            // console.log('Count::', count)
        })

        // console.log("setState Data:::", arr)

        let copy = arr && arr.length > 0 && arr.filter((item)=>{
            if( item.Prepared > 0 ){
               return item
            }
          
       })

        // console.log("RRR on subscription::",copy)
       // console.log("arr::",arr)
          
       if(copy){

           this.setState({
               createDataSpin: false,
               dataSource: copy
           })
       }else{
           this.setState({
               createDataSpin: false,
               dataSource: []
           })
       }

        // this.setState({
        //     createDataSpin: false,
        //     dataSource: arr
        // })
    }


    // createData = (data) => {
    //     //console.log('data:createData:::', data)


    //     let arr = []
    //     data.map((val, i) => {
    //         if (val.orderStatus !== 'DISPATCHED') {
    //             let obj = {}
    //             let count = 0
    //             let totalCount = 0
    //             let newCount = 0
    //             let inProcessCount = 0
    //             obj['id'] = val.id
    //             obj['orderId'] = val.orderId
    //             obj['tokenId'] = val.tokenId
    //             obj['name'] = val.name
    //             obj['tableNo'] = val.tableNo
    //             obj['updatedAt'] = val.updatedAt
    //             obj['timestamp'] = val.timestamp
    //             obj['orderFrom'] = val.orderFrom
    //             obj['subOrderFrom'] = val.subOrderFrom
    //             obj['newOrderFlag'] = val.newOrderFlag
    //             let arr2 = []
    //             val.orderList.map((k) => {
    //                 let obj2 = {}
    //                 obj2['subOrderId'] = k.subOrderId
    //                 let arr3 = []
    //                 k.services.map((s) => {
    //                     let obj3 = { ...s }
    //                     if (s.station == this.props.station && s.status != 'COMPLETED') {
    //                         count++
    //                         totalCount = totalCount + s.qty
    //                         // obj3['serviceId'] = s.serviceId
    //                         // obj3['serviceName'] = s.serviceName
    //                         // obj3['qty'] = s.qty
    //                         // obj3['status'] = s.status
    //                         // obj3['station'] = s.station
    //                         // obj3['isUrgent'] = s.isUrgent
    //                         // obj3['instruction'] = s.instruction
    //                         arr3.push(obj3)

    //                     }
    //                     if (s.station == this.props.station && s.status == 'NEW') {
    //                         newCount = newCount + 1
    //                     }
    //                     if (s.station == this.props.station && s.status == 'IN_PROCESS') {
    //                         inProcessCount = inProcessCount + 1
    //                     }
    //                 })
    //                 if (arr3.length > 0) {
    //                     obj2['services'] = arr3
    //                     arr2.push(obj2)
    //                 }

    //             })
    //             if (arr2.length > 0) {
    //                 obj['orderList'] = arr2
    //                 obj['totalItems'] = count
    //                 obj['newCount'] = newCount
    //                 obj['inProcessCount'] = inProcessCount
    //                 obj['totalCount'] = totalCount
    //                 obj['tableOrderStatus'] = val.tableOrderStatus
    //                 arr.push(obj)
    //             }
    //         }
    //         // console.log('Count::', count)
    //     })

    //     // arrayOfObjects.sort((a, b) => a.age - b.age);

    //     // console.log('arrrr', arr)
    //     this.setState({
    //         createDataSpin: false,
    //         dataSource: arr
    //     })
    // }

    createOnUpdate=(data)=>{
      
       // console.log("called createOnUpdate NewOrder:::",data)
        let updatedData = []
        updatedData.push(data)
 
        let finalData = this.filterBYKOtID(updatedData)
 
        // console.log("finalData:::",finalData)
         
        let finalStateData = this.state.dataSource  && this.state.dataSource.length > 0 ? this.state.dataSource : []
 
        // console.log("DataSOurce::0",finalStateData)
 
        if(finalStateData.length == 0){
        //  console.log("Replaced:::",finalStateData)
         finalStateData = [...finalData, ...finalStateData]
        }else{
            
             for(let i=0; i<finalStateData.length;i++){
                 let index = -1
                 let temp = -1
                 for(let j=0; j<finalStateData.length;j++){
                    index = finalData.findIndex(item => item.kotId == finalStateData[j].kotId)
                    if(index != -1){
                     temp = j
                      break;
                    }
                 }
                   if(index != -1 && temp != -1){
                    //  console.log("inside If:::", index,temp )
                       finalStateData[temp] = finalData[index]
                       finalData.splice(index,1)
                   }
             }
 
            //  console.log("after Splice:::",finalData)
 
              finalStateData = [...finalData, ...finalStateData]
 
        }
              
        //  console.log("finalStateDataMAde::",finalStateData)
        
        let arr = []
        finalStateData.map((val, i) => {
        if(val.orderStatus!=='DISPATCHED'){
            let obj = {}
            let count = 0
            let totalCount = 0
           let newCount=0
           let Prepared = 0
           let inProcessCount = 0
            obj['id'] = val.id
            obj['orderId'] = val.orderId
            obj['kotId'] = val.kotId
            obj['tokenId'] = val.tokenId
            obj['name'] = val.name
            obj['tableNo'] = val.tableNo
            obj['updatedAt'] = val.updatedAt
            obj['timestamp'] = val.timestamp
            obj['orderFrom']=val.orderFrom
            obj['orderSource'] = val.orderSource
            obj['subOrderFrom']=val.subOrderFrom
            obj['newOrderFlag']=val.newOrderFlag
            let arr2 = []
            val.orderList.map((k) => {
                let obj2 = {}
                obj2['subOrderId'] = k.subOrderId
                let arr3 = []
                k.services.map((s) => {
                    let obj3 = {...s}
                    if (s.station==this.props.station  ) {
                        count++
                        totalCount = totalCount + s.qty
                        // obj3['serviceId'] = s.serviceId
                        // obj3['serviceName'] = s.serviceName
                        // obj3['qty'] = s.qty
                        // obj3['status'] = s.status
                        // obj3['station'] = s.station
                        // obj3['isUrgent'] = s.isUrgent
                        // obj3['instruction'] = s.instruction
                        arr3.push(obj3)
 
                    }
                    if(s.station==this.props.station && s.status=='NEW'){
                        // newCount = newCount + 1
                    }
                    if(s.station==this.props.station && s.status=='IN_PROCESS'){
                        inProcessCount = inProcessCount + 1
                    }
                    if(s.station==this.props.station && s.status=='PREPARED'){
                        Prepared = Prepared + 1
                    }
                })
                if (arr3.length > 0) {
                    obj2['services'] = arr3
                    arr2.push(obj2)
                }
 
            })
            if (arr2.length > 0) {
                obj['orderList'] = arr2
                obj['totalItems'] = count
                obj['newCount'] = newCount
                obj['inProcessCount'] = inProcessCount
                obj['Prepared'] = Prepared
                obj['totalCount'] = totalCount
                obj['tableOrderStatus'] = val.tableOrderStatus
                arr.push(obj)
            }
        }
            // console.log('Count::', count)
        })
 
        // console.log("arrCheck::",arr)

        // console.log("copyarrCopy",arr)
        let copy = arr && arr.length > 0 && arr.filter((item)=>{
             if( item.Prepared > 0 ){
                return item
             }
           
        })
 
        //  console.log("RRR on subscription::",copy)
        // console.log("arr::",arr)
           
        if(copy){
 
            this.setState({
                createDataSpin: false,
                dataSource: copy
            })
        }else{
            this.setState({
                createDataSpin: false,
                dataSource: []
            })
        }
 
            // this.setState({
            //     createDataSpin: false,
            //     dataSource: arr
            // })
 
     }

    createDataV2 = (val, serviceStatus) => {
        let arr = this.state.dataSource
        let obj = {}
        let count = 0
        let totalCount = 0
        let newCount = 0
        let inProcessCount = 0
        obj['id'] = val.id
        obj['orderId'] = val.orderId
        obj['tokenId'] = val.tokenId
        obj['name'] = val.name
        obj['tableNo'] = val.tableNo
        obj['updatedAt'] = val.updatedAt
        obj['timestamp'] = val.timestamp
        obj['orderFrom'] = val.orderFrom
        obj['orderSource'] = val.orderSource
        obj['subOrderFrom'] = val.subOrderFrom
        // obj['newOrderFlag']=val.newOrderFlag
        let arr2 = []
        val.orderList.map((k) => {
            let obj2 = {}
            obj2['subOrderId'] = k.subOrderId
            obj2['customFeilds'] = k.customFeilds

            let arr3 = []
            k.services.map((s) => {

                let obj3 = { ...s }

                // if (s.status == serviceStatus && s.station==this.state.station ) {
                if (s.station == this.props.station && s.status == 'PREPARED') {



                    count++
                    totalCount = totalCount + s.qty
                    // obj3['serviceId'] = s.serviceId
                    // obj3['serviceName'] = s.serviceName
                    // obj3['qty'] = s.qty
                    // obj3['status'] = s.status
                    // obj3['station'] = s.station
                    // obj3['isUrgent'] = s.isUrgent
                    // obj3['instruction'] = s.instruction
                    arr3.push(obj3)

                }
                if (s.station == this.props.station && s.status == 'NEW') {
                    newCount = newCount + 1
                }
                if (s.station == this.props.station && s.status == 'IN_PROCESS') {
                    inProcessCount = inProcessCount + 1
                }
            })
            if (arr3.length > 0) {
                obj2['services'] = arr3
                arr2.push(obj2)
            }

        })
        let foundIndex = arr.findIndex(x => x.id == val.id);
        if (arr2.length > 0) {
            obj['orderList'] = arr2
            obj['totalItems'] = count
            obj['totalCount'] = totalCount
            obj['newCount'] = newCount
            obj['inProcessCount'] = inProcessCount
            obj['tableOrderStatus'] = val.tableOrderStatus
            arr && arr.length > 0 && arr.map((p) => {
                p['newOrderFlag'] = false
            })
            obj['newOrderFlag'] = true

            if (foundIndex >= 0) {
               // console.log("Found Index")
                arr[foundIndex] = obj
            } else {
                let flag = localStorage.getItem('SortItem');
                flag = flag === 'true';

                if (flag) {
                    arr.push(obj)
                } else {
                    arr.unshift(obj)
                }


            }



        } else if (foundIndex >= 0) {
            //console.log("removed case")
            arr.splice(foundIndex, 1)
            //console.log("arr:::",arr)
        }
        // arr.unshift(obj)
        // if(arr.length==1 && arr[0].orderList.length==1){
        //     this.getSiteOrder(this.state.kitchenStatus)
        // }

        try {
            // const audioEl = document.getElementsByClassName("audio-element")[0]
            // this.state.updateflag==false  &&  audioEl.play()
            this.setState({
                updateflag: false
            })
        } catch (e) {
            console.log('error catch::', e)
        }
        this.setState({
            createDataSpin: false,
            dataSource: arr
        })
    }

    filterBYKOtID = (orders) => {
        // console.log("orders ::", orders)

        let response = [];
        let tmpKot = {};
        let newSubOrder = null;

        orders.forEach(order => {
            order.orderList.forEach(subOrder => {

                subOrder.services.forEach(service => {
                    // service.subOrderId = subOrder.subOrderId

                    if (service.kotId) {

                        if (!tmpKot[service.kotId]) {

                            tmpKot[service.kotId] = Object.assign({}, order);

                            delete tmpKot[service.kotId].kotNo;

                            tmpKot[service.kotId].orderList = []

                            tmpKot[service.kotId].kotId = service.kotId

                            newSubOrder = {

                                "subOrderId": subOrder.subOrderId,

                                "createdAt": subOrder.createdAt,

                                "services": [Object.assign({}, service)]

                            }

                            tmpKot[service.kotId].orderList.push(newSubOrder);

                        } else {

                            let index = tmpKot[service.kotId].orderList.findIndex(obj => obj.subOrderId == subOrder.subOrderId);

                            if (index == -1) {

                                newSubOrder = {
                                    "subOrderId": subOrder.subOrderId,
                                    "createdAt": subOrder.createdAt,
                                    "services": [Object.assign({}, service)]
                                }

                                tmpKot[service.kotId].orderList.push(newSubOrder);

                            } else {
                                tmpKot[service.kotId].orderList[index].services.push(Object.assign({}, service))
                            }
                        }
                    }
                });
            });


            Object.keys(tmpKot).forEach(kot => {
                response.push(Object.assign({}, tmpKot[kot]))
            });

            // console.log("-------------------")
            tmpKot = {}
        });

        // console.log("response ::", response.length)
        // console.log("response ::", response)

        return response

    }

    showMenuName = (s) =>{

        let name = ''
         if(s.subOrderFrom){
              name = name + unslugify(s.subOrderFrom).toUpperCase()
              
              if(s.orderSource){
                name = name + " (" + unslugify(s.orderSource).toUpperCase() + " )"
              }
         }else{
            name = unslugify(s.orderFrom).toUpperCase()
         }
           
         return name
       
    }

    render() {

        let services = this.state.dataSource
        let TableData = this.props.tableData

        //  console.log("render", this.state.createDataSpin)

        return (
            <>
                {this.state.createDataSpin == true ? <Spin /> :

                    this.state.platform == 'desktop' ? services && services.length > 0 ?
                        <Row gutter={16}>
                            {
                                services.map((s) => {
                                    let tableName = ""
                                    TableData && TableData.length > 0 && TableData.map((item) => {
                                        if (item.typeValue == s.tableNo) {
                                            tableName = item.tableName
                                        }
                                    })


                                    return (
                                        <>
                                            {s.totalItems > 0 &&
                                                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                    <Card className="kitchenCards" style={{ border: s.newOrderFlag && '2px solid red' }}>
                                                        <Row>

                                                            <Col span={s.orderFrom == 'dine-in' ? 8 : 4} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <p className="kitcheNames" style={{ fontSize: '17px' }}>{s.name}</p>
                                                                {/* <div style={{ display: "flex", }}>
                                                                    <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                    <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Token No:</span> {s.tokenId}</p>
                                                                    <p style={{ marginBottom: '0px' }}><span className="kitcheNames">KOT NO :</span> {s.kotId}</p>
                                                                </div> */}



                                                            </Col>
                                                            <Col span={s.orderFrom == 'dine-in' ? 10 : 14} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <div style={{ display: this.state.platform == 'desktop' && 'flex' }}>
                                                                    <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                        <Button
                                                                            style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : s.orderFrom == 'online-menu' ? '#4e45a0' : '#f36b0a', height: '30px' }}>
                                                                            <span style={{ color: 'white' }}>{this.showMenuName(s)}</span></Button>
                                                                    </p>
                                                                    {s.orderFrom == 'dine-in' &&
                                                                        <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                            <Button style={{ borderRadius: '5px', background: '#27cfa0', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                {/* <span className="kitcheNames" style={{ color: 'white' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''} */}
                                                                                <span className="kitcheNames" style={{ color: 'white' }}>{tableName}</span>
                                                                            </span></Button>
                                                                        </p>
                                                                    }
                                                                    <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                        <Button style={{ borderRadius: '5px', background: '#9db519', height: '30px' }}><span style={{ color: 'white' }}>
                                                                            <span className="kitcheNames" style={{ color: 'white' }}>ITEMS:</span> {s.totalCount}
                                                                        </span></Button>
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col span={6} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                </p>

                                                            </Col>
                                                        </Row>
                                                        <div>
                                                                 <div style={{ display: "flex",marginTop:"4px", marginBottom:"10px"}}>
                                                                            <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                            <p style={{ marginBottom: '0px', marginRight: "10px" }}><span className="kitcheNames">Token No:</span> {s.tokenId}</p>
                                                                            <p style={{ marginBottom: '0px' }}><span className="kitcheNames">KOT NO :</span> {s.kotId}</p>
                                                                 </div>
                                                        </div> 
                                                        <hr style={{ marginTop: '0px', marginBottom: '0px' }} />

                                                        {s.orderList && s.orderList.map((order) => {
                                                            return (
                                                                <div
                                                                    id="scrollableDiv"
                                                                    style={{
                                                                        height: 'auto',
                                                                        overflow: 'auto',
                                                                        padding: '0 3px',
                                                                        borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                                                                    }}
                                                                >
                                                                    <List
                                                                        className="kitchenList"
                                                                        dataSource={order.services}
                                                                        renderItem={item => (
                                                                            <>

                                                                                <List.Item key={item.serviceId} className="kitchenItemsBorder">
                                                                                    <List.Item.Meta
                                                                                        className="listData"
                                                                                        title={
                                                                                            <Row>
                                                                                                <Col xs={{ span: 18 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>{item.serviceName}
                                                                                                    {
                                                                                                        item.addons && <p style={{ marginBottom: '0px' }}> (
                                                                                                            {
                                                                                                                item.addons && item.addons.map((add, i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <span>{add.serviceName}</span>
                                                                                                                            {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                            })
                                                                                                        </p>
                                                                                                    }
                                                                                                </Col>
                                                                                                <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 6 }} lg={{ span: 6 }} >Qty: {item.qty}</Col>
                                                                                                {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }} lg={{ span: 2 }} style={{ marginTop: '-7px' }}><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                                {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}
                                                                                            </Row>
                                                                                        }

                                                                                    />

                                                                                    {item.status == 'PREPARED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                Ready to Serve</p>
                                                                                        </div>
                                                                                    }
                                                                                     {item.status == "COMPLETED" &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                COMPLETED</p>
                                                                                        </div>

                                                                                    }
                                                                                    {item.status == 'CANCELLED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: 'red', fontWeight: 600 }}>
                                                                                                CANCELLED</p>
                                                                                        </div>
                                                                                    }

                                                                                    {item.status == 'SHIFTED' &&
                                                                                        <div style={{ margin: 'auto' }}>
                                                                                            <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: 'red', fontWeight: 600 }}>
                                                                                               SHIFTED</p>
                                                                                        </div>
                                                                                    }
                                                                                </List.Item>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            )
                                                        })}


                                                    </Card>
                                                </Col>
                                            }
                                        </>

                                    )
                                })
                            }

                        </Row> : <p style={{textAlign:'center',marginTop:'50px'}}><img src='/public/nodata.png' style={{height:'200px'}}/></p> : <>
                        {
                            services && services.length > 0 ?
                                <Row> {
                                    services.map((s) => {
                                        let tableName = ""

                                        TableData && TableData.length > 0 && TableData.map((item) => {
                                            if (item.typeValue == s.tableNo) {
                                                tableName = item.tableName
                                            }
                                        })

                                        return (
                                            <>
                                                {s.totalItems > 0 &&
                                                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                        <Card className="kitchenCards" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                            <Row>
                                                                <Col span={12}>
                                                                <Row>
                                                                            <Col span={12}>
                                                                            <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                                            {/* <Button style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', height: '23px' }}> */}
                                                                            <span style={{ color: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', fontWeight: 600, fontSize: '17px', }}>{this.showMenuName(s)}</span>
                                                                            {/* </Button> */}
                                                                            </p>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                            {s.orderFrom == 'dine-in' && <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                                                
                                                                                <Button style={{ borderRadius: '5px', background: '#409ee9', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                    <span className="kitcheNames" style={{ color: 'white', fontSize: '12px' }}>{tableName}</span>
                                                                                </span>
                                                                                </Button>
                                                                            </p>
                                                                            }
                                                                            </Col>
                                                                        </Row>
                                                                    
                                                                </Col>
                                                                <Col span={12}>
                                                                    <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                    </p>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                               
                                                                <Col span={24} style={{ textAlign: s.orderFrom == 'dine-in' ? 'end' : 'justify', marginTop: "4px" }} >
                                                                    <div style={{ display: "flex" }}>
                                                                        {/* <p style={{ marginBottom: '0px', marginRight: "15px", }}>
                                                                            <span className="kitcheNames" style={{ lineHeight: '26px',marginBottom: '0px', marginRight: "15px" }}>ORDER ID:{s.orderId ? s.orderId : ''}</span>
                                                                        </p>
                                                                        <p style={{ marginBottom: '0px', marginRight: "15px" }}>
                                                                            <span className="kitcheNames" style={{ lineHeight: '26px' }}>TOKEN NO:{s.tokenId ? s.tokenId : ''}</span>
                                                                        </p>
                                                                        <p style={{ marginBottom: '0px' }}><span className="kitcheNames" style={{ lineHeight: '26px' }}>KOT NO : {s.kotId}</span> </p> */}
                                                                         
                                                                        
                                                                            <span className="kitcheNames" style={{ lineHeight: '26px',marginBottom: '0px', marginRight: "10px",fontSize:"13px" }}>ORDER ID:{s.orderId ? s.orderId : ''}</span>
                                        
                                                                            <span className="kitcheNames" style={{ lineHeight: '26px',marginRight: "10px",fontSize:"13px" }}>TOKEN NO:{s.tokenId ? s.tokenId : ''}</span>
                                                                        
                                                                            <span className="kitcheNames" style={{ lineHeight: '26px',fontSize:"13px" }}>KOT NO : {s.kotId}</span> 
                                                                    
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                            <Row gutter={8} style={{ marginBottom: '10px', marginTop: '0px' }}>
                                                                <Col span={24} style={{ textAlign: 'justify' }}>
                                                                    <span className="kitcheNames" style={{ fontSize: '16px' }}>{s.name}</span>

                                                                </Col>


                                                            </Row>
                                                            <hr style={{ marginTop: '0px', marginBottom: '2px' }} />
                                                            {
                                                                s.orderList && s.orderList.length > 0 && s.orderList.map((order) => {
                                                                    return (
                                                                        <div
                                                                            id="scrollableDiv"
                                                                            style={{
                                                                                height: 'auto',
                                                                                overflow: 'auto',
                                                                                padding: '0 3px',
                                                                                borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                                                                            }}
                                                                        >
                                                                            <List
                                                                                className="kitchenList"
                                                                                dataSource={order.services}
                                                                                renderItem={item => (
                                                                                    <>
                                                                                        {/* {item.status == status && */}
                                                                                        <List.Item key={item.serviceId} className="kitchenItemsBorder">
                                                                                            <List.Item.Meta
                                                                                                className="listData"
                                                                                                // avatar={<Avatar src={item.picture.large} />}
                                                                                                title={<Row gutter={16}>
                                                                                                    <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 18 }} lg={{ span: 18 }}>{item.serviceName}
                                                                                                        {item.addons &&
                                                                                                            <p style={{ marginBottom: '0px' }}> (
                                                                                                                {item.addons && item.addons.map((add, i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <span>{add.serviceName}</span>
                                                                                                                            {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                                } )

                                                                                                            </p>
                                                                                                        }
                                                                                                    </Col>
                                                                                                    <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 6 }} >Qty: {item.qty}</Col>
                                                                                                    {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }} lg={{ span: 2 }} style={{ marginTop: '-7px' }}><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                                    {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}
                                                                                                </Row>
                                                                                                }

                                                                                            />

                                                                                            {item.status == 'PREPARED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        Ready to Serve</p>
                                                                                                </div>
                                                                                            }

                                                                                            {item.status == 'COMPLETED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                     COMPLETED</p>
                                                                                                </div>
                                                                                            }

                                                                                            {item.status == 'CANCELLED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                    CANCELLED</p>
                                                                                                </div>
                                                                                            }  

                                                                                            {item.status == 'SHIFTED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                    SHIFTED</p>
                                                                                                </div>
                                                                                            } 

                                                                                        </List.Item>

                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                            <Row gutter={10} style={{ marginTop: '5px' }}>
                                                                <Col span={8}>

                                                                    <p style={{ marginBottom: '0px', textAlign: 'justify', marginTop: '0px' }}>
                                                                        <Button style={{ borderRadius: '5px', width: '100%', background: 'none', height: '30px' }}><span style={{ color: 'white' }}>
                                                                            <span className="kitcheNames" style={{ fontSize: '12px', fontWeight: 700 }}>ITEMS:&nbsp; {s.totalCount}</span>
                                                                        </span>
                                                                        </Button>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                }
                                            </>

                                        )

                                    })

                                }
                                </Row> :  <p style={{textAlign:'center',marginTop:'50px'}}><img src='/public/nodata.png' style={{height:'200px'}}/></p>
                        }
                    </>

                }
            </>

        )
    }
}

export default withApollo(serveItems)