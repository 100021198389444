import React, { Children } from 'react'
import compose from 'lodash/flowRight';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import GetSetting from '../../queries/getJewelSiteSetting'
import  { SettingContext, settingData } from '../../context/SettingContext'
import MediaQuery from 'react-responsive';
// import KitchenRoleRoutes from './kitchenRoleRoute'
import KitchenRoleRoutes from './allStationRoleRoutes'

// const CustomRoleRoutes = React.lazy(() => import('./waiterAdminRoutes'));
// const KitchenRoleRoutes = React.lazy(() => import('./kitchenRoleRoute'));


class kitchenRoleAdmin extends React.Component{
 constructor(props){
        super(props)
        this.state={
            platform:''
        }
       
    }

  componentDidMount=()=>{
// console.log("get Route",this.props.getJewelSiteSetting.getJewelSiteSetting)
let siteOption=this.props.data && this.props.data.getSite && this.props.data.getSite && this.props.data.getSite.basicSiteSetting && this.props.data.getSite.basicSiteSetting.siteOptions
if(siteOption!=null && siteOption!=""){
    localStorage.setItem('siteOp',siteOption)

}

//     if(this.props.getJewelSiteSetting.getJewelSiteSetting && this.props.getJewelSiteSetting.getJewelSiteSetting.inventory){

// if(this.props.getJewelSiteSetting.getJewelSiteSetting.inventory){
//         localStorage.setItem('inventory',this.props.getJewelSiteSetting.getJewelSiteSetting.inventory)
//     }
//       if (window.screen.width >= 700) { 
//         //   1280
//         this.setState({ 
//             platform:'desktop'
//         })
//        }else{
//         this.setState({
//             platform:'mobile'
//         })
//        }

//   }
}
    render(){
        const {children} = this.props
        return(
         
           <KitchenRoleRoutes {...this.props} platform={this.state.platform}>
               {children}
            </KitchenRoleRoutes>
      
        )
    }
}


kitchenRoleAdmin.contextType = SettingContext
const WrapkitchenRoleAdmin = compose(
    // withRouter,
    // graphql(GetSetting,{
    //     name: 'getJewelSiteSetting',
    //     options: props => ({
    //         fetchPolicy: "network-only"
    //     })
    // })
   
  )(kitchenRoleAdmin)
export default WrapkitchenRoleAdmin