

import React from 'react';

export const userData = {
    email: '',
    username: '',
    phoneNumber: '',
    fqdnMut: '',
    siteId: ''
}

export const AuthContext = React.createContext({
    ...userData
});
