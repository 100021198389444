import gql from 'graphql-tag';
export default gql`
query getCategoryBySiteIdV2($limit: Int, $nextToken: String) {
    getCategoryBySiteIdV2(limit: $limit, nextToken: $nextToken) {
      items {
        id
        slugName
        image {
          bucket
          region
          key
        }
        serviceName
        categoryName
        categoryStatus
        createdAt
        displayOrder
        attachedGrp
        isGroup
        isAddon
        discountType
        discountValue
        isDiscountEditable
        isAddon
        desc
        isItemType
        hoursOfOperation {
          day
          status
          operationSlots {
            start
            end
          }
        }
      }
      nextToken
    }
  }
  `


// import gql from 'graphql-tag';
// export default gql`
// query getCategoryBySiteIdV2($limit: Int $nextToken: String){
//     getCategoryBySiteIdV2(limit: $limit nextToken: $nextToken){
//       items{
//         id
// 		slugName
//         serviceName
//         categoryName
//         categoryStatus
//         createdAt
// 		displayOrder
//     },
//       nextToken
//   }

// }`