import gql from 'graphql-tag'
export default gql`
query{
    getServiceTypes{
      id
      siteId
      slugName
      typeName
      typeDes
      typeStatus
      displayOrder
      masterServiceType
    }
  }`