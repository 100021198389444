import moment from 'moment'
const calculateTime = (data,colorTimeObject,isNewKot ) => {
       
    // console.log("colorTimeObject :::",colorTimeObject,isNewKot )

    // return
    let id, time, adjustedHours, orderTimeInMinutes, delayLimitInMinutes, now, currentMinutes, timeDifference, color, ele;

    var x = setInterval(() => {

        for (let i = 0; i < data.length; i++) {
            //   console.log("Loop::", data[i].kotId)
            if(isNewKot){
            id = `NewKotOrder${ data[i].kotId}${data[i].orderId}`
            }else{
            id = `InProcessKotOrder${ data[i].kotId}${data[i].orderId}`
            }
            time = moment.unix(data[i].timestamp).format('hh:mm a');
            const [timePart, period] = time.split(' ');
            const [hours, minutes] = timePart.split(':');
            adjustedHours = period === 'pm' && parseInt(hours, 10) !== 12
                ? parseInt(hours, 10) + 12
                : period === 'am' && parseInt(hours, 10) === 12
                    ? 0
                    : parseInt(hours, 10);

            orderTimeInMinutes = adjustedHours * 60 + parseInt(minutes, 10);

            // console.log(`Total minutes: ${orderTimeInMinutes}`);

            delayLimitInMinutes = 5; // Set your delay limit
            now = new Date();
            currentMinutes = now.getHours() * 60 + now.getMinutes();

            // console.log("currentMinutes:::", currentMinutes);

            timeDifference = currentMinutes - orderTimeInMinutes;
             
            if(colorTimeObject.green){
                color = 'rgb(0 250 0 / 10%)'
            }
          
            if ( colorTimeObject.orange && timeDifference > colorTimeObject.green) {
                // console.log(`Order is delayed by ${timeDifference} minutes, exceeding the limit of ${delayLimitInMinutes} minutes. kot id ${kotID}`);
                color = 'rgb(246 204 166 / 30%)'
            }
            if ( colorTimeObject.red && timeDifference > colorTimeObject.orange) {
                color = 'rgb(248 164 154 / 20%)'
            }

            //  console.log("color::::",color,"Kot ID",kotID)

            ele = document.getElementById(id)
            // console.log("ele",ele)
            if (ele) {
                // console.log("iffLL")
                ele.style.backgroundColor = color
            }
        }

    },  10000);

     return x

    //  spanElement.style.backgroundColor = backgroundColor;

    // return color
}

export {
    calculateTime
};