import gql from 'graphql-tag'

export default gql`
query{
    getHotelStations{
      id
      domain
      typeValue
      stationName
      enabled
      assignedUsers
      createdAt
    }
  }
`


