import gql from 'graphql-tag'
export default gql`subscription onTableUpdated( $siteId: String!){
  onTableUpdated(siteId: $siteId){
    siteId
    typeValue
    tableStatus
    isRefreshMenu
  }
}



# {
#   "siteId": "b148b379-a018-44e3-ae54-644bac405262"
# }
`