import moment from 'moment'
const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const CountDownTimer = (ids,status) => {
    // console.log('ids:::',ids)
let id,newDate,year,fullDate,min,currentDate,sdate,find,countDownDate1
    let splitString = '#'

    if(status == "NEW"){
        splitString = '-'
    }
    
    var x = setInterval(() =>{
        for(let i=0;i<ids.length;i++){
 
            id = ids[i];
            newDate = parseInt(id.split(splitString)[1])
            
        let preparationTime =  parseInt(id.split(splitString)[2])
            
             year = moment.unix(newDate).format('ll')
             min = moment.unix(newDate).format('HH:mm:ss')
             fullDate = new Date(`${year} ${min}`)
             currentDate = addMinutes(fullDate, preparationTime)
             sdate = moment(currentDate).format('HH:mm:ss');
          find = `${addZero(month[currentDate.getMonth()])} ${addZero(currentDate.getDate())},${addZero(currentDate.getFullYear())} ${sdate}`
           countDownDate1 = new Date(find).getTime();
        
        var now = new Date().getTime();
        var distance = countDownDate1 - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let k = document.getElementById(id)
        // console.log("distance:::",distance,status)
        if (distance> 0 && k) {
            document.getElementById(id).innerHTML =
                + minutes + "m " + seconds + "s ";
        }else{
            let k = document.getElementById(id)
            if (k) {
                document.getElementById(id).innerHTML = `<span style="color:red" id="${ids[i]}TimeOut">Time Out</span>`;

            }
        }

      
    }
    }, 1000);


    return x;
    
}

export default CountDownTimer

const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
}

const addZero = (i) => {
    if (i < 10) { i = "0" + i }
    return i;
}