import React from 'react'
import unslugify from "unslugify";
import { Card, Row, Col, Button, List, Select, Badge } from "antd";
import { Tabs } from 'antd';
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus';
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2'
import { calculateTime } from '../../utils/calculateTime';
import { AndroidPostMsg } from '../../utils/AndroidPostMsg';
import NewOrders from './newOrders';
import { withRouter } from 'react-router';
import moment from 'moment'
import tableOrderCreated from '../../subscription/tableOrderCreated';
import CountDownTimer from '../../utils/CountDownTimer';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Item from 'antd/lib/list/Item';

const { TabPane } = Tabs;
const { Option } = Select;

class Services extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newKots: [],
            createDataSpin: false,
            platform: '',
            finalCount: null,
            Sort: localStorage.getItem('SortItem') && localStorage.getItem('SortItem') == 'true' ? true : false,
            type: "Preparing",
            clearIntervalFlagNewKot: undefined,
            clearPreprationTimeNewKot: undefined
            //  stateForQuery : this.props.station

        }
    }


    componentDidMount = () => { 

        if(this.state.clearPreprationTimeNewKot){
            clearInterval(this.state.clearPreprationTimeNewKot)
        }

        this.getSiteOrderForNewKOT()

        if (this.state.newKots && this.state.newKots.length > 0) {
            // console.log("called Did Update:::")
           // this.playAudio()
        }

        if (window.screen.width >= 700) {
            //   1280
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }

        this.tableOrderUpdatedV2NewOrdersSubscriptionCall()
        this.tableOrderCreatedSubscriptionForNew()
    }
    
    componentDidUpdate(prevProps, prevState) {
        // console.log("component did Update called:::",prevState.newKots)
        if (prevState.newKots.length !== this.state.newKots.length) {
          if (this.state.newKots.length === 0) {
            // console.log("inside STop Did Update:::")
            // If playCount is 0, this.pauseAudio();
            AndroidPostMsg("STOP_BELL")
            const audioEl = document.getElementsByClassName("audio-element")[0]
            if (audioEl) {
                // audioEl.loop = true
                // console.log("AUdio PAused:::")
                audioEl.pause()
            }
          }
        }
    }

    componentWillUnmount(){
        // console.log("component will unmount:::")
        if(this.subscriptionV2){
            this.subscriptionV2.unsubscribe()
        }

        if(this.subscriptionNew){
            this.subscriptionNew.unsubscribe()
        }

       

        if(this.state.clearPreprationTimeNewKot){
            clearInterval(this.state.clearPreprationTimeNewKot)
        }
    }


    playAudio = () => {

        if (localStorage.getItem('mute') == 'true') {
             
            var Name = "Unknown OS";
            if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows OS";
            if (navigator.userAgent.indexOf("Mac") != -1) Name = "Macintosh";
            if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux OS";
            if (navigator.userAgent.indexOf("Android") != -1) Name = "Android OS";
            if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";

            if(Name == 'Android OS' && window && window.ReactNativeWebView){
                AndroidPostMsg("START_BELL")
            }else{
            // console.log("Inside SOund Play Function:::")
            const audioEl = document.getElementsByClassName("audio-element")[0]
            if (audioEl) {
                audioEl.loop = true
                audioEl.play()
                
            }
           }
        }

    }


    getSiteOrderForNewKOT = () => {
        //  console.log(" this.props.station called:::", this.props.station)
        this.loadingSpin()
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'network-only',
            variables: {
                station: this.props.station,
                // station:'kitchen',
                status: ['KOT_GENERATED'],
                skip: 0,
                limit: 1000
                // tableNo:'1'

            }
        })
            .then(({ data }) => {
                // console.log("getSiteOrderForNewKOT called:::", data)
                if (data.getSiteOrderByItemStatus != null) {

                    let dataCopy = JSON.parse(JSON.stringify(data.getSiteOrderByItemStatus))


                    this.createDataForNewKot(dataCopy)
                    // this.getOrderItemsOnSubscribe(data.getSiteOrderByItemStatus)
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: query error: ${JSON.stringify(err, null, 2)}`, err)
            })
    }

    tableOrderUpdatedV2NewOrdersSubscriptionCall = () => {
        let that = this
        let retryAttempts = 0;
        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables: {
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({ data }) => {
                //  console.log("restaurantData tableOrderUpdatedV2 ::",data.tableOrderUpdatedV2)
                retryAttempts = 0;
                let item = data.tableOrderUpdatedV2

                if (item.tableOrderStatus == "CLOSED") {
                    //  console.log("Order Removed::", item)
                    let FilterDataSOurce = this.state.newKots.filter((order) => order.orderId != item.orderId)
                     //console.log("FilterDataSource:::",FilterDataSOurce)

                    if (FilterDataSOurce) {
                        this.setState({ newKots: FilterDataSOurce })
                    } else {
                        this.setState({ newKots: [] })
                    }
                }

                if (item.tableOrderStatus != "CLOSED") {
                    this.createOnUpdate(data.tableOrderUpdatedV2)
                }
            },
            error: (err) => {
                retryAttempts++;
                console.log('tableOrderUpdatedV2 error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  // Calculate the exponential backoff delay (adjust as needed)
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderUpdatedV2NewOrdersSubscriptionCall, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

    tableOrderCreatedSubscriptionForNew = () =>{  
        let that = this;
        let retryAttempts = 0;
        this.subscriptionNew = this.props.client.subscribe({
            query: tableOrderCreated,
            variables:{
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({data}) => {  

                  retryAttempts = 0;
                  this.createOnUpdate(data.tableOrderCreated)
                

                
            },
            error: (err) => {
                retryAttempts++;
                console.log('tableOrderCreated error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  // Calculate the exponential backoff delay (adjust as needed)
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderCreatedSubscriptionForNew, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

    getPreparationTime(data, status) {

        if(status != "Preparing" && this.props.clearPreprationTime ){
            clearInterval(this.props.clearPreprationTime)
        }

        if(this.state.clearPreprationTimeNewKot){
            clearInterval(this.state.clearPreprationTimeNewKot)
        }

        if(status != "Preparing" ){
        let ids = []  
        if(data && data.length > 0){
           for(let i=0;i<data.length;i++){
            if(data[i].preparationTime){
                ids.push(`${data[i].orderId}-${data[i].timestamp}-${data[i].preparationTime}`)
            }
           }
          let interVal =  CountDownTimer(ids,"NEW")

          this.setState({
            clearPreprationTimeNewKot: interVal
          })
           
        }
    }
           
    } 

    loadingSpin = () => {
        this.setState({
            createDataSpin: !this.state.createDataSpin
        })
    }

    createDataForNewKot = (data) => {
        // console.log('data:createData:::', data)

        let finalData = this.filterBYKOtID(data)

        // console.log('finalData:::', finalData)

        let arr = []
        finalData.map((val, i) => {
            if (val.orderStatus !== 'DISPATCHED') {
                let obj = {}
                let count = 0
                let totalCount = 0
                let newCount = 0
                let inProcessCount = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['kotId'] = val.kotId
                obj['tokenId'] = val.tokenId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['preparationTime'] = val.preparationTime
                obj['updatedAt'] = val.updatedAt
                obj['timestamp'] = val.timestamp
                obj['orderFrom'] = val.orderFrom
                obj['orderSource'] = val.orderSource
                obj['subOrderFrom'] = val.subOrderFrom
                obj['newOrderFlag'] = val.newOrderFlag
                let arr2 = []
                val.orderList.map((k) => {
                    let obj2 = {}
                    obj2['subOrderId'] = k.subOrderId
                    let arr3 = []
                    k.services.map((s) => {
                        let obj3 = { ...s }
                        if (s.station == this.props.station) {
                            count++
                            totalCount = totalCount + 1
                            // obj3['serviceId'] = s.serviceId
                            // obj3['serviceName'] = s.serviceName
                            // obj3['qty'] = s.qty
                            // obj3['status'] = s.status
                            // obj3['station'] = s.station
                            // obj3['isUrgent'] = s.isUrgent
                            // obj3['instruction'] = s.instruction
                            arr3.push(obj3)

                        }
                        if (s.station == this.props.station && s.status == 'NEW') {
                            // newCount = newCount + 1
                        }
                        if (s.station == this.props.station && s.status == 'IN_PROCESS') {
                            inProcessCount = inProcessCount + 1
                        }
                    })
                    if (arr3.length > 0) {
                        obj2['services'] = arr3
                        arr2.push(obj2)
                    }

                })
                if (arr2.length > 0) {
                    obj['orderList'] = arr2
                    obj['totalItems'] = count
                    obj['newCount'] = newCount
                    obj['inProcessCount'] = inProcessCount
                    obj['totalCount'] = totalCount
                    obj['tableOrderStatus'] = val.tableOrderStatus
                    arr.push(obj)
                }
            }
            // console.log('Count::', count)
        })

        // console.log("setState Data:::", arr)

        if (arr && arr.length > 0) {
            // console.log("audio play for create Data::::")
            this.playAudio()
        }


        if(this.state.clearPreprationTimeNewKot){
            clearInterval(this.state.clearPreprationTimeNewKot)
        }
          

        this.getPreparationTime(arr,this.state.type)

        this.setState({
            createDataSpin: false,
            newKots: arr
        })
    }

    createOnUpdate = (data) => {

        // console.log("called createOnUpdate NewOrder:::",data)
        let updatedData = []
        updatedData.push(data)

        let finalData = this.filterBYKOtID(updatedData)
        let finalStateData = this.state.newKots
        // console.log("finalData:::",finalData)

        let ringData = this.filterBYKOtIDForKotGenerated(updatedData)
        // console.log("ringData:::",ringData)

        let flagRing = false
        for (let i = 0; i < ringData.length; i++) {
            // console.log("Item:::",ringData[i])
            let index = finalStateData.findIndex(item => item.kotId == ringData[i].kotId)
            if (index == -1) {
                //    temp = j
                // console.log("Not found:::")
                flagRing = true
                break;
            }
        }



        if (flagRing) {
            this.playAudio()
        }

        // if(ringData.length == 0){
        //     AndroidPostMsg("STOP_BELL")
        //     const audioEl = document.getElementsByClassName("audio-element")[0]
        //     if (audioEl) {
        //         // audioEl.loop = true
        //         // console.log("AUdio PAused:::")
        //         audioEl.pause()
        //     }
        // }

        //  console.log("DataSOurce::0",finalStateData)



        if (finalStateData.length == 0) {
            //  console.log("Replaced:::",finalStateData)
            finalStateData = [...finalData, ...finalStateData]
        } else {

            for (let i = 0; i < finalStateData.length; i++) {
                let index = -1
                let temp = -1
                for (let j = 0; j < finalStateData.length; j++) {
                    index = finalData.findIndex(item => item.kotId == finalStateData[j].kotId && item.orderId == finalStateData[j].orderId)
                    if (index != -1) {
                        temp = j
                        break;
                    }
                }
                if (index != -1 && temp != -1) {
                    //  console.log("inside If:::", index,temp )
                    finalStateData[temp] = finalData[index]
                    finalData.splice(index, 1)
                }
            }

            //  console.log("after Splice:::",finalData)

            finalStateData = [...finalData, ...finalStateData]

        }

        //   console.log("finalStateDataMAde::",finalStateData)

        let arr = []
        finalStateData.map((val, i) => {
            if (val.orderStatus !== 'DISPATCHED') {
                let obj = {}
                let count = 0
                let totalCount = 0
                let newCount = 0
                let inProcessCount = 0
                obj['id'] = val.id
                obj['orderId'] = val.orderId
                obj['kotId'] = val.kotId
                obj['tokenId'] = val.tokenId
                obj['name'] = val.name
                obj['tableNo'] = val.tableNo
                obj['preparationTime'] = val.preparationTime
                obj['updatedAt'] = val.updatedAt
                obj['timestamp'] = val.timestamp
                obj['orderFrom'] = val.orderFrom
                obj['orderSource'] = val.orderSource
                obj['subOrderFrom'] = val.subOrderFrom
                obj['newOrderFlag'] = val.newOrderFlag
                let arr2 = []
                val.orderList.map((k) => {
                    let obj2 = {}
                    obj2['subOrderId'] = k.subOrderId
                    let arr3 = []
                    k.services.map((s) => {
                        let obj3 = { ...s }
                        if (s.station == this.props.station && s.status == 'KOT_GENERATED') {
                            count++
                            totalCount = totalCount + 1
                            // obj3['serviceId'] = s.serviceId
                            // obj3['serviceName'] = s.serviceName
                            // obj3['qty'] = s.qty
                            // obj3['status'] = s.status
                            // obj3['station'] = s.station
                            // obj3['isUrgent'] = s.isUrgent
                            // obj3['instruction'] = s.instruction
                            arr3.push(obj3)

                        } 
                        if (s.station == this.props.station && s.status == 'NEW') {
                            // newCount = newCount + 1
                        }
                        if (s.station == this.props.station && s.status == 'IN_PROCESS') {
                            inProcessCount = inProcessCount + 1
                        }
                    })
                    if (arr3.length > 0) {
                        obj2['services'] = arr3
                        arr2.push(obj2)
                    }

                })
                if (arr2.length > 0) {
                    obj['orderList'] = arr2
                    obj['totalItems'] = count
                    obj['newCount'] = newCount
                    obj['inProcessCount'] = inProcessCount
                    obj['totalCount'] = totalCount
                    obj['tableOrderStatus'] = val.tableOrderStatus
                    arr.push(obj)
                }
            }
            // console.log('Count::', count)
        })

        // console.log("arrCheck::",arr)
        
        let copy = arr

        let TimeData = []
      let objDummy
      for(let i=0;i<copy.length;i++){
         objDummy = {
              kotId: copy[i].kotId,
              timestamp: copy[i].timestamp,
              orderId: copy[i].orderId
          }

          TimeData.push(objDummy)
      }

       if (this.state.clearIntervalFlagNewKot) {
           clearInterval(this.state.clearIntervalFlagNewKot)
       }

       if(this.state.clearPreprationTimeNewKot){
        clearInterval(this.state.clearPreprationTimeNewKot)
       }

       if(this.state.type != "Preparing" && this.props.clearIntervalFlag){
           clearInterval(this.props.clearIntervalFlag)
       }

       if(this.state.type != "Preparing" && this.props.clearPreprationTime ){
           clearInterval(this.props.clearPreprationTime)
       }

      
       let colorTimeObject = {};

       this.props.trackerConfig && this.props.trackerConfig.forEach(item => {
           colorTimeObject[item.color] = item.time;
       });
       let response
       if (this.props.trackerConfig && this.state.type != "Preparing" ) {
           response = calculateTime(TimeData, colorTimeObject ,true)
       }

       this.getPreparationTime(arr,this.state.type)

        this.setState({
            createDataSpin: false,
            newKots: arr,
            clearIntervalFlagNewKot:response
        })

        this.tempCalCulation(TimeData, colorTimeObject ,true)

    }

    tempCalCulation = async(data,colorTimeObject,isNewKot) =>{ 
        await this.waitTwoSeconds()
        let id, time, adjustedHours, orderTimeInMinutes, delayLimitInMinutes, now, currentMinutes, timeDifference, color, ele;

        for (let i = 0; i < data.length; i++) {
          if(isNewKot){
          id = `NewKotOrder${ data[i].kotId}${data[i].orderId}`
          }else{
          id = `InProcessKotOrder${ data[i].kotId}${data[i].orderId}`
          }
          time = moment.unix(data[i].timestamp).format('hh:mm a');
          const [timePart, period] = time.split(' ');
          const [hours, minutes] = timePart.split(':');
          adjustedHours = period === 'pm' && parseInt(hours, 10) !== 12
              ? parseInt(hours, 10) + 12
              : period === 'am' && parseInt(hours, 10) === 12
                  ? 0
                  : parseInt(hours, 10);

          orderTimeInMinutes = adjustedHours * 60 + parseInt(minutes, 10);

          // console.log(`Total minutes: ${orderTimeInMinutes}`);

          delayLimitInMinutes = 5; // Set your delay limit
          now = new Date();
          currentMinutes = now.getHours() * 60 + now.getMinutes();

          // console.log("currentMinutes:::", currentMinutes);

          timeDifference = currentMinutes - orderTimeInMinutes;
           
          if(colorTimeObject.green){
              color = 'rgb(0 250 0 / 10%)'
          }
        
          if ( colorTimeObject.orange && timeDifference > colorTimeObject.green) {
              // console.log(`Order is delayed by ${timeDifference} minutes, exceeding the limit of ${delayLimitInMinutes} minutes. kot id ${kotID}`);
              color = 'rgb(246 204 166 / 30%)'
          }
          if ( colorTimeObject.red && timeDifference > colorTimeObject.orange) {
              color = 'rgb(248 164 154 / 20%)'
          }

          //  console.log("color::::",color,"Kot ID",kotID)

          ele = document.getElementById(id)
          if (ele) {
              // console.log("iffLL")
              ele.style.backgroundColor = color
          }
      }
    }

    filterBYKOtID = (orders) => {
        // console.log("orders ::", orders)

        let response = [];
        let tmpKot = {};
        let newSubOrder = null;

        orders.forEach(order => {
            order.orderList.forEach(subOrder => {

                subOrder.services.forEach(service => {
                    // service.subOrderId = subOrder.subOrderId

                    if (service.kotId && service.station == this.props.station) {

                        if (!tmpKot[service.kotId]) {
                            order.timestamp = subOrder.createdAt 
                            tmpKot[service.kotId] = Object.assign({}, order);

                            delete tmpKot[service.kotId].kotNo;

                            tmpKot[service.kotId].orderList = []

                            tmpKot[service.kotId].kotId = service.kotId

                            newSubOrder = {

                                "subOrderId": subOrder.subOrderId,

                                "createdAt": subOrder.createdAt,

                                "services": [Object.assign({}, service)]

                            }

                            tmpKot[service.kotId].orderList.push(newSubOrder);

                        } else {

                            let index = tmpKot[service.kotId].orderList.findIndex(obj => obj.subOrderId == subOrder.subOrderId);

                            if (index == -1) {

                                newSubOrder = {
                                    "subOrderId": subOrder.subOrderId,
                                    "createdAt": subOrder.createdAt,
                                    "services": [Object.assign({}, service)]
                                }

                                tmpKot[service.kotId].orderList.push(newSubOrder);

                            } else {
                                tmpKot[service.kotId].orderList[index].services.push(Object.assign({}, service))
                            }
                        }
                    }
                });
            });


            Object.keys(tmpKot).forEach(kot => {
                response.push(Object.assign({}, tmpKot[kot]))
            });

            // console.log("-------------------")
            tmpKot = {}
        });

        // console.log("response ::", response.length)
        // console.log("response ::", response)

        return response

    }

    filterBYKOtIDForKotGenerated = (orders) => {
        // console.log("orders ::", orders)

        // console.log("getting previous station reference resolved",this.props.station)

        let response = [];
        let tmpKot = {};
        let newSubOrder = null;

        orders.forEach(order => {
            order.orderList.forEach(subOrder => {

                subOrder.services.forEach(service => {
                    // service.subOrderId = subOrder.subOrderId

                    if (service.kotId && service.status == 'KOT_GENERATED' && service.station ==  this.props.station) {

                        if (!tmpKot[service.kotId]) {
                            order.timestamp = subOrder.createdAt 
                            tmpKot[service.kotId] = Object.assign({}, order);

                            delete tmpKot[service.kotId].kotNo;

                            tmpKot[service.kotId].orderList = []

                            tmpKot[service.kotId].kotId = service.kotId

                            newSubOrder = {

                                "subOrderId": subOrder.subOrderId,

                                "createdAt": subOrder.createdAt,

                                "services": [Object.assign({}, service)]

                            }

                            tmpKot[service.kotId].orderList.push(newSubOrder);

                        } else {

                            let index = tmpKot[service.kotId].orderList.findIndex(obj => obj.subOrderId == subOrder.subOrderId);

                            if (index == -1) {

                                newSubOrder = {
                                    "subOrderId": subOrder.subOrderId,
                                    "createdAt": subOrder.createdAt,
                                    "services": [Object.assign({}, service)]
                                }

                                tmpKot[service.kotId].orderList.push(newSubOrder);

                            } else {
                                tmpKot[service.kotId].orderList[index].services.push(Object.assign({}, service))
                            }
                        }
                    }
                });
            });


            Object.keys(tmpKot).forEach(kot => {
                // console.log("tmpKot[kot]",tmpKot[kot])
                response.push({ ...tmpKot[kot] });
                // response.push(Object.assign({}, tmpKot[kot]))
            });

            // console.log("-------------------")
            tmpKot = {}
        });

        // console.log("response ::", response.length)
        // console.log("response filterBYKOtIDForKotGenerated::", response)

        return response

    }

    onChange = (e) => {

        //console.log("onChange::",e)

        //return
        localStorage.setItem('SortItem', e);
        this.setState({ Sort: e })


        this.props.getSiteOrder()
        // console.log("Called::::",e.target.checked)
    }

    onTabChange = (e) => {

        if (e == "new") {
            let copy = this.state.newKots
            let TimeData = []
            let objDummy
            for (let i = 0; i < copy.length; i++) {
                objDummy = {
                    kotId: copy[i].kotId,
                    timestamp: copy[i].timestamp,
                    orderId: copy[i].orderId
                }

                TimeData.push(objDummy)
            }

            if (this.state.clearIntervalFlagNewKot) {
                clearInterval(this.state.clearIntervalFlagNewKot)
            }

            if (this.props.clearIntervalFlag) {
                clearInterval(this.props.clearIntervalFlag)
            }

            if( this.props.clearPreprationTime ){
                clearInterval(this.props.clearPreprationTime)
            }

            let colorTimeObject = {};

            this.props.trackerConfig && this.props.trackerConfig.forEach(item => {
                colorTimeObject[item.color] = item.time;
            });

            let response
            if (this.props.trackerConfig) {
                this.tempCalCulation(TimeData, colorTimeObject, true)
                response = calculateTime(TimeData, colorTimeObject, true)
            }

            this.setState({ type: e, clearIntervalFlagNewKot: response })

            this.getPreparationTime(this.state.newKots , "new")
        } else {
            let copy = this.props.serviceData

            let TimeData = []
            let objDummy
            for (let i = 0; i < copy.length; i++) {
                objDummy = {
                    kotId: copy[i].kotId,
                    timestamp: copy[i].timestamp,
                    orderId: copy[i].orderId
                }

                TimeData.push(objDummy)
            }

            //    console.log("TimeData:::",TimeData)

            if (this.state.clearIntervalFlagNewKot) {
                clearInterval(this.state.clearIntervalFlagNewKot)
            }

            if(this.state.clearPreprationTimeNewKot){
                clearInterval(this.state.clearPreprationTimeNewKot)
               }

            if (this.props.clearIntervalFlag) {
                clearInterval(this.props.clearIntervalFlag)
            }

            if( this.props.clearPreprationTime ){
                clearInterval(this.props.clearPreprationTime)
            }

            this.props.getPreparationTime(this.props.serviceData)

            // if( this.props.clearPreprationTime ){
            //     clearInterval(this.props.clearPreprationTime)
            // }

            let colorTimeObject = {};

            this.props.trackerConfig && this.props.trackerConfig.forEach(item => {
                colorTimeObject[item.color] = item.time;
            });
            let response
            // console.log("colorTimeObject",colorTimeObject);
            if (this.props.trackerConfig) {
                this.tempCalCulation(TimeData, colorTimeObject, false)
                response = calculateTime(TimeData, colorTimeObject, false)
            }

            this.setState({ type: e, clearIntervalFlagNewKot: response })

           
        }


    }

     waitTwoSeconds() {
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    }

    KotAccept = (orderList, id, kotID) => {
        if (this.state.newKots.length == 1) {
            AndroidPostMsg("STOP_BELL")
            const audioEl = document.getElementsByClassName("audio-element")[0]
            if (audioEl) {
                // audioEl.loop = true
                // console.log("AUdio PAused:::")
                audioEl.pause()
            }
        }
        this.props.AcceptKot(orderList, id, kotID)
    }

    
    showMenuName = (s) =>{

        let name = ''
         if(s.subOrderFrom){
              name = name + unslugify(s.subOrderFrom).toUpperCase()
              
              if(s.orderSource){
                name = name + " (" + unslugify(s.orderSource).toUpperCase() + " )"
              }
         }else{
            name = unslugify(s.orderFrom).toUpperCase()
         }
           
         return name
       
    }

    render() {
        // let status = this.props.kitchenStatus
        let status = 'NEW'
        var services = this.props.serviceData
        let TableData = this.props.tableData
        // console.log('services: services by service status:::', services)
        // console.log("this.state.newKots Render:::",this.state.newKots)
        let newOrdersCount = this.state.newKots.length

        //  console.log("s:::",services)
        return (

            <div>
                {this.state.platform == 'desktop' ?
                    <>
                        <Tabs style={{ marginTop: "-20px", display: "flex", justifyContent: "start" }} className='tabsByCategory' defaultActiveKey={this.state.type} onChange={this.onTabChange} >
                            <TabPane  tab={
                                <Badge className='PreparingBageCount' count={services.length} offset={[20, 2]}>

                                    <span style={{ color: this.state.type == 'Preparing' ? "white" : "black",fontSize:"1rem" }}>PREPARING</span>

                                </Badge>
                            } key="Preparing">

                                <Select defaultValue={this.state.Sort} style={{ marginBottom: "10px",marginTop:"-76px",position:"absolute" }} onChange={this.onChange}>
                                    <Option value={true}>Oldest To Newest</Option>
                                    <Option value={false}>Newest To Oldest</Option>
                                </Select>

                                {services && services.length > 0 ?
                                    <Row gutter={16}>
                                        {services.map((s) => {
                                          
                                            let tableName = ""

                                            TableData && TableData.length > 0 && TableData.map((item) => {
                                                if (item.typeValue == s.tableNo) {
                                                    tableName = item.tableName
                                                }
                                            })
                                    
                                            return (
                                                <>
                                                    {s.totalItems > 0 &&
                                                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Card className="kitchenCards" id={`InProcessKotOrder${s.kotId}${s.orderId}`} style={{ border: s.newOrderFlag && '2px solid red' }}>
                                                                <Row>

                                                                    <Col span={s.orderFrom == 'dine-in' ? 8 : 4} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <p className="kitcheNames" style={{ fontSize: '17px' }}>{s.name ? s.name.split(' ')[0] : ''}</p>
                                                                    
                                                                    </Col>
                                                                    <Col span={s.orderFrom == 'dine-in' ? 10 : 14} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <div style={{ display: this.state.platform == 'desktop' && 'flex' }}>
                                                                            <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                                <Button
                                                                                    style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : s.orderFrom == 'online-menu' ? '#4e45a0' : '#f36b0a', height: '30px' }}>
                                                                                    <span style={{ color: 'white' }}>{this.showMenuName(s)}</span></Button>
                                                                            </p>
                                                                            {s.orderFrom == 'dine-in' &&
                                                                                <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                                    <Button style={{ borderRadius: '5px', background: '#27cfa0', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                        {/* <span className="kitcheNames" style={{ color: 'white' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''} */}
                                                                                        <span className="kitcheNames" style={{ color: 'white' }}>{tableName}</span>
                                                                                    </span></Button>
                                                                                </p>
                                                                            }
                                                                            <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                                <Button style={{ borderRadius: '5px', background: '#9db519', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                    <span className="kitcheNames" style={{ color: 'white' }}>ITEMS:</span> {s.totalCount}
                                                                                </span></Button>
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={6} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{  fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                     

                                                               {s.preparationTime && <div>
                                                                 <p style={{ marginBottom: '0px', fontSize: "17px", color: "red", textAlign: "right", fontWeight: 600 }} className="kitcheNames" > PREP TIME : <span id={`${s.orderId}#${s.timestamp}#${s.preparationTime}`}></span> </p>
                                                                </div> }
                                                                
                                                                 <div style={{ display: "flex", justifyContent:"space-between",  gap:"10px" ,marginBottom:"10px"}}>
                                                                    <div  style={{display: "flex", gap:"10px" , marginTop:"20px"}}>
                                                                            <p style={{ marginBottom: '0px',  }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                            <p style={{ marginBottom: '0px', }}><span className="kitcheNames">Token No:</span> {s.tokenId}</p>
                                                                            <p style={{ marginBottom: '0px' }}><span className="kitcheNames">KOT NO :</span> {s.kotId}</p>
                                                                     </div>  

                                                                     <div>
                                                                     <p>
                                                                            {s.newCount > 0 &&
                                                                                <p style={{ textAlign: 'right', marginBottom: '0px', marginTop: '5px' }}>
                                                                                    <Button loading={this.props.kotId == s.kotId && this.props.acceptAllLoading} style={{ background: '#0d85b3', color: 'white', marginTop: '0px', marginBottom: '0px', textAlign: 'center', borderRadius: '5px' }}
                                                                                        onClick={() => this.props.AcceptallOrder(s.orderList, s.id, s.kotId)}>Accept All</Button></p>
                                                                            }
                                                                        </p>

                                                                        <p>
                                                                            {s.newCount == 0 && s.inProcessCount != 0 &&
                                                                                <p style={{ textAlign: 'right', marginBottom: '0px', marginTop: '5px' }}>
                                                                                    <Button loading={this.props.kotId == s.kotId  && this.props.mainId == s.id && this.props.acceptAllLoading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '0px', textAlign: 'center', borderRadius: '5px' }}
                                                                                        onClick={() => this.props.ReadyAllOrder(s.orderList, s.id, s.kotId)}>Ready All</Button></p>
                                                                            }
                                                                        </p>
                                                                    </div> 
                                                                    
                                                                 </div>
                                                               
                                                                
                                                                <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                                                                {s.orderList && s.orderList.map((order) => {
                                                                    return (
                                                                        <div
                                                                            id="scrollableDiv"
                                                                            style={{
                                                                                height: 'auto',
                                                                                overflow: 'auto',
                                                                                padding: '0 3px',
                                                                                // border: '1px solid rgba(140, 140, 140, 0.35)',
                                                                            }}
                                                                        >
                                                                            <List
                                                                                className="kitchenList"
                                                                                dataSource={order.services}
                                                                                renderItem={item => ( (item.status == 'IN_PROCESS' || item.status == "CANCELLED" || item.status == 'PREPARED' || item.status == 'SHIFTED') ?  
                                                                                    <>
                                                                                        {/* {this.showCancelItemNotification(item)} */}
                                                                                        {/* {item.status == status && */}
                                                                                        <List.Item key={item.serviceId} className="kitchenListItem">
                                                                                            <List.Item.Meta
                                                                                                className="listData"
                                                                                                // avatar={<Avatar src={item.picture.large} />}
                                                                                                title={<Row style={{ marginTop: '10px' }}>
                                                                                                    <Col xs={{ span: 16 }} sm={{ span: 10 }} md={{ span: 16 }} lg={{ span: 16 }}><span style={{ fontWeight: 'bold', fontSize: '16px' , textDecoration: (item.status == 'CANCELLED' || item.status == 'SHIFTED' ) && "line-through" }}>{item.serviceName}</span>
                                                                                                        {item.addons &&
                                                                                                            <p style={{ marginBottom: '0px' }}>(
                                                                                                                {item.addons && item.addons.map((add, i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <span>{add.serviceName}</span>
                                                                                                                            {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                                }
                                                                                                                )
                                                                                                            </p>
                                                                                                        }
                                                                                                        {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600, marginTop: '-5px', marginBottom: '0px', fontSize: '13px' }}>{`(${item.instruction})`}</p>}

                                                                                                    </Col>

                                                                                                    <Col xs={{ span: 20 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} style={{textDecoration: (item.status == 'CANCELLED' || item.status == 'SHIFTED' ) && "line-through"}}>Qty: {item.qty}</Col>
                                                                                                    {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }} ><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                                </Row>}
                                                                                            />
                                                                                            {item.status == 'NEW' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#23AE5C', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', borderRadius: '5px', width: '100px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}>Accept</Button></p></div>
                                                                                            }
                                                                                            {item.status == 'IN_PROCESS' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', borderRadius: '5px', width: '100px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}>Ready</Button></p></div>
                                                                                            }
                                                                                            {item.status == 'PREPARED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        Ready to Serve</p>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.status == "COMPLETED" &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        COMPLETED</p>
                                                                                                </div>
                                                                                            }
                                                                                            {item.status == 'CANCELLED' &&
                                                                                                <div style={{ margin: 'auto', marginRight: '18px' }}>
                                                                                                    <p style={{ textAlign: 'center', color: 'red', fontWeight: 600, marginTop: '10px' }}>
                                                                                                        CANCELLED</p>
                                                                                                </div>
                                                                                            }
                                                                                            {item.status == 'SHIFTED' &&
                                                                                                <div style={{ margin: 'auto', marginRight: '18px' }}>
                                                                                                    <p style={{ textAlign: 'center', color: 'red', fontWeight: 600, marginTop: '10px' }}>
                                                                                                      SHIFTED</p>
                                                                                                </div>
                                                                                            }
                                                                                        </List.Item>

                                                                                    </> : <></>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Card>
                                                        </Col>
                                                    }
                                                </>

                                            )
                                        })
                                        }
                                    </Row>
                                    :
                                    <p style={{ textAlign: 'center', marginTop: '50px' }}><img src='/public/nodata.png' style={{ height: '200px' }} /></p>
                                }

                            </TabPane>
                            <TabPane tab={
                                <Badge className='bageCount' count={newOrdersCount} offset={[20, 2]}>

                                    <span style={{ color: this.state.type == 'new' ? "white" : "black",fontSize:"1rem" }}>NEW</span>

                                </Badge>
                            } key="new">
                                <NewOrders mainId={this.props.mainId} kotId={this.props.kotId} acceptAllLoading={this.props.acceptAllLoading} siteId={this.props.siteId} AcceptKot={this.props.AcceptKot} KotAccept={this.KotAccept} tableData={this.props.tableData} dataSource={this.state.newKots} station={this.props.station} />
                            </TabPane>
                        </Tabs>



                    </>

                    :

                    <>

                        <Tabs style={{ marginTop: "-20px", display: "flex", justifyContent: "start" }} className='tabsByCategoryMobile' defaultActiveKey={this.state.type} onChange={this.onTabChange} >
                            <TabPane style={{marginRight:"15px"}} className='mobPrepBagCount' tab={<Badge count={services.length} offset={[12, -1]}>

                                <span style={{ color: this.state.type == 'Preparing' ? "white" : "black",fontSize:"0.7rem",marginLeft:"5px" }}>PREPARING</span>

                            </Badge>} key="Preparing">
                                <Select defaultValue={this.state.Sort} style={{ width: '60%', marginBottom: "10px", display: "flex", justifyContent: "left",marginTop:"-18px" }} onChange={this.onChange}>
                                    <Option value={true}>Oldest To Newest</Option>
                                    <Option value={false}>Newest To Oldest</Option>
                                </Select>

                                {services && services.length > 0 ?
                                    <Row gutter={16}>
                                        {services && services.length > 0 && services.map((s) => {
                                            let tableName = ""
                                            
                                            TableData && TableData.length > 0 && TableData.map((item) => {
                                                if (item.typeValue == s.tableNo) {
                                                    tableName = item.tableName
                                                }
                                            })
                                            return (
                                                <>
                                                    {s.totalItems > 0 &&
                                                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Card className="kitchenCards" id={`InProcessKotOrder${s.kotId}${s.orderId}`} style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                                <Row>
                                                                    <Col span={12}> 
                                                                        <Row>
                                                                            <Col span={12}>
                                                                            <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                                            {/* <Button style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', height: '23px' }}> */}
                                                                            <span style={{ color: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', fontWeight: 600, fontSize: '14px', }}>{this.showMenuName(s)}</span>
                                                                            {/* </Button> */}
                                                                            </p>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                            {s.orderFrom == 'dine-in' && <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                                                
                                                                                <Button style={{ borderRadius: '5px', background: '#409ee9', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                    <span className="kitcheNames" style={{ color: 'white', fontSize: '12px' }}>{tableName}</span>
                                                                                </span>
                                                                                </Button>
                                                                            </p>
                                                                            }
                                                                            </Col>
                                                                        </Row>
                                                                                                                
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                                        </p>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    
                                                                    <Col span={24} style={{ textAlign: s.orderFrom == 'dine-in' ? 'end' : 'justify', marginTop: "4px" }} >
                                                                        <div style={{ display: "flex" }}>
                                                                            <p style={{ marginBottom: '0px', marginRight: "15px", }}>
                                                                                <span className="kitcheNames" style={{ lineHeight: '26px' }}>ORDER ID:{s.orderId ? s.orderId : ''}</span>
                                                                            </p>
                                                                            <p style={{ marginBottom: '0px', marginRight: "15px" }}>
                                                                                <span className="kitcheNames" style={{ lineHeight: '26px' }}>TOKEN NO:{s.tokenId ? s.tokenId : ''}</span>
                                                                            </p>
                                                                            <p style={{ marginBottom: '0px' }}>
                                                                                <span className="kitcheNames" style={{ lineHeight: '26px' }}>KOT NO:{s.kotId ? s.kotId : ''}</span>
                                                                            </p>
                                                                        </div>
                                                                    </Col>

                                                                </Row>
                                                                <Row gutter={8} style={{ marginBottom: '10px', marginTop: '0px' }}>
                                                                    <Col span={12} style={{ textAlign: 'justify' }}>
                                                                        <span className="kitcheNames" style={{ fontSize: '16px' }}>{s.name}</span>
                                                                    </Col>

                                                                    <Col span={12}>

                                                                        {s.newCount > 0 &&
                                                                            <p style={{ textAlign: 'justify', marginBottom: '0px' }}>
                                                                                <Button loading={this.props.mainId == s.id && this.props.acceptAllLoading}
                                                                                    style={{ background: '#0d85b3', color: 'white', width: '100%', textAlign: 'center', borderRadius: '5px', height: '30px' }}
                                                                                    onClick={() => this.props.AcceptallOrder(s.orderList, s.id)}><span className="kitcheNames" style={{ color: 'white' }}>Accept All</span>
                                                                                </Button>
                                                                            </p>
                                                                        }

                                                                        {s.newCount == 0 && s.inProcessCount != 0 &&
                                                                            <p style={{ textAlign: 'right', marginBottom: '0px', marginTop: '5px' }}>
                                                                                <Button loading={this.props.kotId == s.kotId && this.props.mainId == s.id && this.props.acceptAllLoading } style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.ReadyAllOrder(s.orderList, s.id, s.kotId)} ><span style={{ fontSize: '12px' }}
                                                                                >Ready All </span> </Button></p>
                                                                        }

                                                                    </Col>
                                                                </Row>
                                                                <div>
                                                                {s.preparationTime && <p  style={{ marginTop:"-10px", marginBottom: '0px', textAlign:"left", fontSize:"17px", color: "red", fontWeight: 600}} className="kitcheNames" > PREP TIME : <span  id={`${s.orderId}#${s.timestamp}#${s.preparationTime}`}></span> </p>}
                                                                </div>
                                                             
                                                                <hr style={{ marginTop: '0px', marginBottom: '2px' }} />
                                                                {s.orderList && s.orderList.map((order) => {
                                                                    return (
                                                                        <div
                                                                            id="scrollableDiv"
                                                                            style={{
                                                                                height: 'auto',
                                                                                overflow: 'auto',
                                                                                padding: '0 3px',
                                                                                borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                                                                            }}
                                                                        >
                                                                            <List
                                                                                className="kitchenList"
                                                                                dataSource={order.services}
                                                                                renderItem={item => ( (item.status == 'IN_PROCESS' || item.status == "CANCELLED" || item.status == 'PREPARED' || item.status == 'SHIFTED') ?  
                                                                                    <>
                                                                                        {/* {item.status == status && */}
                                                                                        <List.Item key={item.serviceId} className="kitchenItemsBorder">
                                                                                            <List.Item.Meta
                                                                                                className="listData"
                                                                                                // avatar={<Avatar src={item.picture.large} />}
                                                                                                title={<Row>
                                                                                                    <Col xs={{ span: 18 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>{item.serviceName}
                                                                                                        {item.addons &&
                                                                                                            <p style={{ marginBottom: '0px' }}>(
                                                                                                                {item.addons && item.addons.map((add, i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <span>{add.serviceName}</span>
                                                                                                                            {item.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                                }
                                                                                                                )
                                                                                                            </p>
                                                                                                        }
                                                                                                    </Col>
                                                                                                    <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }} >Qty: {item.qty}</Col>
                                                                                                    {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }} lg={{ span: 2 }} style={{ marginTop: '-7px' }}><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                                    {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}
                                                                                                </Row>
                                                                                                }

                                                                                            />
                                                                                            {item.status == 'NEW' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#23AE5C', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}><span style={{ fontSize: '12px' }}>ACCEPT</span></Button></p></div>
                                                                                            }
                                                                                            {item.status == 'IN_PROCESS' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.props.serviceId == item.uId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status, item.uId)}><span style={{ fontSize: '12px' }}>Ready</span></Button></p></div>

                                                                                            }
                                                                                            {item.status == 'PREPARED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        Ready to Serve</p>
                                                                                                </div>
                                                                                            }
                                                                                            {item.status == "COMPLETED" &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        COMPLETED</p>
                                                                                                </div>

                                                                                            }

                                                                                            {item.status == 'CANCELLED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: 'red', fontWeight: 600 }}>
                                                                                                        CANCELLED</p>
                                                                                                </div>
                                                                                            }
                                                                                            
                                                                                            {item.status == 'SHIFTED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: 'red', fontWeight: 600 }}>
                                                                                                      SHIFTED</p>
                                                                                                </div>
                                                                                            }
                                                                                            
                                                                                        </List.Item>
                                                                                        {/* } */}
                                                                                    </> : <></>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                                <Row gutter={10} style={{ marginTop: '5px' }}>
                                                                    <Col span={8}>

                                                                        <p style={{ marginBottom: '0px', textAlign: 'justify', marginTop: '0px' }}>
                                                                            <Button style={{ borderRadius: '5px', width: '100%', background: 'none', height: '30px' }}><span style={{ color: 'white' }}>
                                                                                <span className="kitcheNames" style={{ fontSize: '12px', fontWeight: 700 }}>ITEMS:&nbsp; {s.totalCount}</span>
                                                                            </span>
                                                                            </Button>
                                                                        </p>
                                                                    </Col>
                                                                    {/* <Col span={16}>
                                            <p>
                                              {status !== 'PREPARED' &&
                                                            <p style={{marginBottom: '0px',}}> <Button loading={this.props.mainId == s.id && this.props.acceptAllLoading} style={{ background: '#0d85b3', color: 'white', marginTop: '0px', marginBottom: '0px', textAlign: 'center', borderRadius: '5px',width:'100%',height:'30px' }} onClick={() => this.props.AcceptallOrder(s.orderList, s.id)}><span style={{fontSize:'12px'}}>ACCEPT ALL</span></Button></p>
                                                        }
                                                    </p>
                                            </Col> */}
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    }
                                                </>

                                            )
                                        })
                                        }
                                    </Row>
                                    :

                                    <div >

                                        {/* <Button style={{ display: 'block', backgroundColor: "#333333", margin: "0" }} onClick={() => this.props.history.goBack()} type="primary"> <ArrowLeftOutlined /> Go Back </Button> */}

                                        <p style={{ textAlign: 'center', marginTop: '50px' }}><img src='/public/nodata.png' style={{ height: '200px' }} /></p>
                                    </div>
                                }

                            </TabPane>
                            <TabPane tab={
                                <Badge className='bageCount' count={newOrdersCount} offset={[20, 2]}>

                                    <span style={{ color: this.state.type == 'new' ? "white" : "black",fontSize:"0.7rem" }}>NEW</span>

                                </Badge>
                            } key="new">
                                <NewOrders mainId={this.props.mainId} kotId={this.props.kotId} acceptAllLoading={this.props.acceptAllLoading} siteId={this.props.siteId} AcceptKot={this.props.AcceptKot} KotAccept={this.KotAccept} tableData={this.props.tableData} dataSource={this.state.newKots} station={this.props.station} />
                            </TabPane>
                        </Tabs>





                    </>
                }
            </div>
        )
    }
}
const wrappServices = Form.create({ name: "JBooking_form" })(Services);

export default withRouter(withApollo(wrappServices));
