import React from 'react'
import { Card, Row, Col, Button, List, Avatar, Tag } from "antd";
import moment from 'moment'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

class AllStationServices extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            platform: '',
            dynamicSpan: { rowWidth: '100%', colWidth: 24, nextColSpan: 12 },
            stationData: this.props.stationWiseData.length,
            serviceId:this.props.serviceId
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps:::', nextProps)
        let allOrders = nextProps.stationWiseData
        let obj = this.state.dynamicSpan
        if (allOrders.length == 2) {
            obj = {
                rowWidth: '100%',
                colWidth: 12,
                nextColSpan: 12
            }
        } else if (allOrders.length == 3) {
            console.log('else:::::')
            obj = {
                rowWidth: '100%',
                colWidth: 8,
                nextColSpan: 24
            }
        } else if (allOrders.length == 4) {
            obj = {
                rowWidth: '100%',
                colWidth: 6,
                nextColSpan: 24
            }
        } else if (allOrders.length > 4) {
            let k = allOrders.length - 4
            let t = (25 * k) + 100
            obj = {
                rowWidth: t.toString(),
                colWidth: 6,
                nextColSpan: 24
            }
        }
        this.setState({
            dynamicSpan: obj,
            serviceId:nextProps.serviceId
        })
    }
    componentDidMount = () => {
        if (window.screen.width >= 700) {
            //   1280
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }





    }

    render() {
        // let status = this.props.kitchenStatus
        const { dynamicSpan } = this.state
        let status = 'NEW'
        let sData = this.props.stationWiseData

        // console.log('services::: render stationwise services',stationWiseData)
        let services = null
        return (
            <div>
                {this.state.platform == 'desktop' ?
                    <Row gutter={16} style={{ width: dynamicSpan.rowWidth, background: 'white' }} >

                        {sData && sData.length > 0 && sData.map((ser) => {
                            return (
                                <Col span={dynamicSpan.colWidth}>
                                    <Card className='kitchenAllStationCard'>
                                        <h2 style={{ textAlign: 'center' }}>{ser.station && ser.station.split('::')[1] && ser.station.split('::')[1].toUpperCase()}</h2>

                                        <Row gutter={16}>
                                            {ser && (ser.orderList).length > 0 && (ser.orderList).map((s) => {
                                                return (
                                                    <>
                                      
                                                        <Col span={dynamicSpan.nextColSpan} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                            <Card className="kitchenCards">
                                                                <Row>
                                                                    <Col span={7} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <p className="kitcheNames" style={{ fontSize: '17px' }}>{s.name}</p>
                                                                        {/* <div style={{display:'flex'}}> */}
                                                                        {/* </div> */}

                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                                            <Tag style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : s.orderFrom == 'online-menu' ? '#c81c10' : '#f36b0a', }}><span style={{ color: 'white' }}>{s.orderFrom && s.orderFrom.toUpperCase()}</span></Tag>
                                                                        </p>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format(' hh:mm a')}</span>&nbsp;
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col span={8} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                                                        <p style={{ marginBottom: '0px' }}><span className="kitcheNames">Order Id:</span> {s.orderId}</p>
                                                                    </Col>
                                                                    <Col span={8}>
                                                                        <div style={{ display: this.state.platform == 'desktop' && 'flex' }}>

                                                                            <span className="kitcheNames" style={{ color: 'black' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''}
                                                                            {/* 
                                                        {s.orderFrom == 'dine-in' &&
                                                        <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                                                            <Button style={{ borderRadius: '5px', background: '#27cfa0', height: '30px' }}><span style={{ color: 'white' }}>
                                                                <span className="kitcheNames" style={{ color: 'black' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''}
                                                            </span></Button>
                                                        </p>
                                                         } */}

                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col span={8} style={{ textAlign: 'right' }}>

                                                                        <Tag color='red' style={{ marginBottom: '5px' }}>ITEMS:{s.totalCount}</Tag>
                                                                    </Col> */}

                                                                </Row>
                                                                <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
                                                                {s.orderList && s.orderList.length> 0  && s.orderList.map((order) => {
                                                                    // <>
                                                                    // {order.services && order.services.length> 0 &&
                                                                    
                                                                    return (
                                                                        <>
                                                                        {order.services && order.services.length>0 &&
                                                                        <div
                                                                            id="scrollableDiv"
                                                                            style={{
                                                                                height: 'auto',
                                                                                overflow: 'auto',
                                                                                padding: '0 3px',
                                                                                // border: '1px solid rgba(140, 140, 140, 0.35)',
                                                                            }}
                                                                        >
                                                                            <List
                                                                                className="kitchenList"
                                                                                dataSource={order.services}
                                                                                renderItem={item => (
                                                                                    <>
                                                                                        {/* {item.status == status && */}
                                                                                        <List.Item key={item.serviceId} className="kitchenListItem">
                                                                                            <List.Item.Meta
                                                                                                className="listData"
                                                                                                // avatar={<Avatar src={item.picture.large} />}
                                                                                                title={<Row>
                                                                                                    <Col xs={{ span: 16 }} sm={{ span: 10 }} md={{ span: 16 }} lg={{ span: 16 }}>{item.serviceName}
                                                                                                        {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}

                                                                                                    </Col>

                                                                                                    <Col xs={{ span: 20 }} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }} >{item.qty}</Col>
                                                                                                    {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 4 }} sm={{ span: 4 }} md={{ span: 2 }} lg={{ span: 2 }} ><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                                </Row>}
                                                                                            />
                                                                                            {item.status == 'NEW' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.state.serviceId == item.serviceId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#23AE5C', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', borderRadius: '5px', width: '100px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status)}>Accept</Button></p></div>
                                                                                            }
                                                                                            {item.status == 'IN_PROCESS' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                                        <Button loading={this.state.serviceId == item.serviceId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', borderRadius: '5px', width: '100px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status)}>Prepared</Button></p></div>
                                                                                            }
                                                                                            {item.status == 'PREPARED' &&
                                                                                                <div style={{ margin: 'auto' }}>
                                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                                        Ready to Serve</p>
                                                                                                </div>
                                                                                            }
                                                                                        </List.Item>

                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                }
                                                                        </>
                                                                    )
                                                                    
                                                                // }
                                                                    // </>
                                                                
                                                                })}
                                                            </Card>
                                                        </Col>
                                                    </>

                                                )
                                            })
                                            }
                                        </Row>

                                    </Card>
                                </Col>
                            )
                        })}
                        {/* </Col> */}
                    </Row>
                    :
                    <Row gutter={16}>
                        {services && services.length > 0 && services.map((s) => {
                            return (
                                <>
                                    {s.totalItems > 0 &&
                                        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <Card className="kitchenCards" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                                <Row>
                                                    <Col span={12}>
                                                        <p style={{ marginBottom: '0px', textAlign: 'left' }}>
                                                            {/* <Button style={{ borderRadius: '5px', background: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', height: '23px' }}> */}
                                                            <span style={{ color: s.orderFrom == 'dine-in' ? '#1b34b1' : '#f36b0a', fontWeight: 600, fontSize: '17px', }}>{s.orderFrom.toUpperCase()}</span>
                                                            {/* </Button> */}
                                                        </p>
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className="kitcheNames" style={{ textAlign: 'right' }}> <span style={{ fontWeight: 500 }}>{s.timestamp && moment.unix(s.timestamp).format('DD-MM-YYYY hh:mm a')}</span>&nbsp;
                                                        </p>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    {s.orderFrom == 'dine-in' &&
                                                        <Col span={12}>

                                                            <p style={{ marginBottom: '0px', textAlign: 'justify' }}>
                                                                {/* <Button style={{ borderRadius: '5px', background: '#c38c12', height: '30px',width:'100%' }}> */}
                                                                {/* <span className="kitcheNames"  style={{fontSize:'16px'}}>{s.name}</span>  */}
                                                                {/* </Button> */}
                                                                <Button style={{ borderRadius: '5px', background: '#409ee9', height: '30px' }}><span style={{ color: 'white' }}>
                                                                    <span className="kitcheNames" style={{ color: 'white', fontSize: '12px' }}>TABLE NO:&nbsp;{s.tableNo ? s.tableNo.split('::')[1] : ''}</span>
                                                                </span>
                                                                </Button>
                                                            </p>

                                                        </Col>
                                                    }
                                                    <Col span={12} style={{ textAlign: s.orderFrom == 'dine-in' ? 'end' : 'justify' }} >
                                                        <p style={{ marginBottom: '0px' }}>
                                                            {/* <Button style={{ borderRadius: '5px', background:'#e77e25', }}><span style={{ color: 'white' }}> */}
                                                            <span className="kitcheNames" style={{ lineHeight: '26px' }}>ORDER ID:{s.orderId ? s.orderId : ''}</span>
                                                            {/* </span> */}
                                                            {/* </Button> */}
                                                        </p>
                                                    </Col>

                                                </Row>
                                                <Row gutter={8} style={{ marginBottom: '10px', marginTop: '0px' }}>
                                                    <Col span={24} style={{ textAlign: 'justify' }}>
                                                        <span className="kitcheNames" style={{ fontSize: '16px' }}>{s.name}</span>

                                                    </Col>

                                                    {/* {status !== 'PREPARED' &&
                                                            <p style={{ textAlign: 'justify',marginBottom:'0px'}}> <Button loading={this.props.mainId == s.id && this.props.acceptAllLoading} style={{ background: '#0d85b3', color: 'white',width:'100%', textAlign: 'center', borderRadius: '5px',height:'30px' }} onClick={() => this.props.AcceptallOrder(s.orderList, s.id)}><span className="kitcheNames" style={{color:'white'}}>Accept All</span></Button></p>
                                                        } */}
                                                    {/* </Col> */}
                                                </Row>
                                                <hr style={{ marginTop: '0px', marginBottom: '2px' }} />
                                                {s.orderList && s.orderList.map((order) => {
                                                    return (
                                                        <div
                                                            id="scrollableDiv"
                                                            style={{
                                                                height: 'auto',
                                                                overflow: 'auto',
                                                                padding: '0 3px',
                                                                borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                                                            }}
                                                        >
                                                            <List
                                                                className="kitchenList"
                                                                dataSource={order.services}
                                                                renderItem={item => (
                                                                    <>
                                                                        {/* {item.status == status && */}
                                                                        <List.Item key={item.serviceId} className="kitchenItemsBorder">
                                                                            <List.Item.Meta
                                                                                className="listData"
                                                                                // avatar={<Avatar src={item.picture.large} />}
                                                                                title={<Row>
                                                                                    <Col xs={{ span: 18 }} sm={{ span: 12 }} md={{ span: 18 }} lg={{ span: 18 }}>{item.serviceName}</Col>
                                                                                    <Col xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 6 }} lg={{ span: 6 }} >Qty: {item.qty}</Col>
                                                                                    {item.status != 'PREPARED' && item.isUrgent == true && <Col xs={{ span: 6 }} sm={{ span: 6 }} md={{ span: 2 }} lg={{ span: 2 }} style={{ marginTop: '-7px' }}><span style={{ color: 'red' }}>Urgent</span></Col>}
                                                                                    {item.instruction && item.instruction != '' && <p style={{ color: 'red', fontWeight: 600 }}>{`(${item.instruction})`}</p>}
                                                                                </Row>
                                                                                }

                                                                            />
                                                                            {item.status == 'NEW' &&
                                                                                <div style={{ margin: 'auto' }}>
                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                        <Button loading={this.props.serviceId == item.serviceId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#23AE5C', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status)}><span style={{ fontSize: '12px' }}>ACCEPT</span></Button></p></div>
                                                                            }
                                                                            {item.status == 'IN_PROCESS' &&
                                                                                <div style={{ margin: 'auto' }}>
                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px' }}>
                                                                                        <Button loading={this.props.serviceId == item.serviceId && order.subOrderId == this.props.subOrderId && this.props.loading} style={{ background: '#F14A52', color: 'white', marginTop: '0px', marginBottom: '-7px', textAlign: 'center', height: '27px', borderRadius: '5px', lineHeight: '17px' }} onClick={() => this.props.AcceptPrepared(s.id, order.subOrderId, item.serviceId, item.status)}><span style={{ fontSize: '12px' }}>PREPARE</span></Button></p></div>

                                                                            }
                                                                            {item.status == 'PREPARED' &&
                                                                                <div style={{ margin: 'auto' }}>
                                                                                    <p style={{ textAlign: 'center', marginBottom: '7px', marginTop: '7px', color: '#3f51b5', fontWeight: 600 }}>
                                                                                        Ready to Serve</p>
                                                                                </div>
                                                                            }
                                                                        </List.Item>
                                                                        {/* } */}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    )
                                                })}

                                                <Row gutter={10} style={{ marginTop: '5px' }}>
                                                    <Col span={8}>

                                                        <p style={{ marginBottom: '0px', textAlign: 'justify', marginTop: '0px' }}>
                                                            <Button style={{ borderRadius: '5px', width: '100%', background: 'none', height: '30px' }}><span style={{ color: 'white' }}>
                                                                <span className="kitcheNames" style={{ fontSize: '12px', fontWeight: 700 }}>ITEMS:&nbsp; {s.totalCount}</span>
                                                            </span>
                                                            </Button>
                                                        </p>
                                                    </Col>
                                                    {/* <Col span={16}>
                                            <p>
                                              {status !== 'PREPARED' &&
                                                            <p style={{marginBottom: '0px',}}> <Button loading={this.props.mainId == s.id && this.props.acceptAllLoading} style={{ background: '#0d85b3', color: 'white', marginTop: '0px', marginBottom: '0px', textAlign: 'center', borderRadius: '5px',width:'100%',height:'30px' }} onClick={() => this.props.AcceptallOrder(s.orderList, s.id)}><span style={{fontSize:'12px'}}>ACCEPT ALL</span></Button></p>
                                                        }
                                                    </p>
                                            </Col> */}
                                                </Row>
                                            </Card>
                                        </Col>
                                    }
                                </>

                            )
                        })
                        }
                    </Row>
                }
            </div>
        )
    }
}
const wrappAllStationServices = Form.create({ name: "JBooking_form" })(AllStationServices);

export default wrappAllStationServices