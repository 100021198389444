import gql from 'graphql-tag'
export default gql`
query {
    getPlatformServicesByAdmin{
     id
     siteId
     serviceId
     serviceName
     slugServiceName
     status
     masterService
     platform
     siteType
     createdAt
     serviceType
   }
   }
`