import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, NavBar, Button, Accordion } from 'antd-mobile';
import { CodeSandboxOutlined, LogoutOutlined,SyncOutlined } from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import { Auth } from 'aws-amplify';
import { AndroidPostMsg } from '../../../utils/AndroidPostMsg';
// import '../../assets/custom.min.css';
// Amplify.configure(aws_exports);

// const { Header, Sider, Content } = Layout;
const text = <span>Admin</span>;
const { SubMenu } = Menu;

class MLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      activemenuKey: 0,
      domain: '',
      audioEnabled: localStorage.hasOwnProperty('mute') ? localStorage.getItem('mute') == 'true' : true
    };
  }

  componentDidMount(){
    if (!localStorage.hasOwnProperty('mute')) {
      // Key is not present in local storage, set it
      localStorage.setItem('mute', JSON.stringify(true))
    }
    
  }

  setSoundOff = () =>{
    AndroidPostMsg("STOP_BELL")
    const audioEl = document.getElementsByClassName("audio-element")[0]
    if(audioEl){
      // AndroidPostMsg("STOP_BELL")
        audioEl.pause()
    }

    localStorage.setItem('mute', JSON.stringify(false))
    this.setState({
      audioEnabled: false
    })
  }

  setSoundOn = () =>{
    localStorage.setItem('mute', JSON.stringify(true))
    this.setState({
      audioEnabled: true
    })
  }

  //   handleSignOut = () => {
  //     localStorage.removeItem("inventory")
  //     Auth.signOut()
  //       .then(() => window.location.reload(true))
  //       .catch(err => console.log(err));
  //   };

  handleSignOut = async () => {
    await Auth.currentUserInfo()
      .then((userData) => {
        let Device = this.checkDevice()
        console.log("handleSignOut ::", Device)
        if (Device == 'Android OS') {
          let deviceId = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${userData.username}.deviceKey`)
          let data = {
            deviceId: deviceId,
            siteId: localStorage.getItem('siteId'),
            username: userData.username
          }
          let obj = {
            "eventName": "LOGOUT",
            "payload": JSON.stringify(data)
          }
          window.ReactNativeWebView?.postMessage(JSON.stringify(obj))
        }
      })
    localStorage.removeItem('inventory')
    localStorage.removeItem('siteOp')
    localStorage.removeItem("siteId")
    localStorage.removeItem("role")
    Auth.signOut()
      .then(() => {
        window.location = window.location.origin;
        // window.location.reload(true)
      })
      .catch(err => console.log(err));
  };
  checkDevice = () => {
    var Name = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows OS";
    if (navigator.userAgent.indexOf("Mac") != -1) Name = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux OS";
    if (navigator.userAgent.indexOf("Android") != -1) Name = "Android OS";
    if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";
    return Name
  }
  onOpenChange = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  syncData = () => {
    console.log("inside SyncData::::")
    location.reload(true);
  }

  render() {


    const { children } = this.props;

    const { activemenuKey } = this.state;

    const menuList = [
      {
        title: 'Products',
        to: '/jewelProduct',
        icon: 'code-sandbox'
      },

    ];

    const sidebar = menuList.map((m, i) => {

      return (
        <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]} defaultOpenKeys={[this.state.defaultOpen]} >
          <Menu.Item key="1">
            <Link to="/">
              <CodeSandboxOutlined />
              <span className="nav-text">Orders</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <LogoutOutlined />
            <span onClick={this.handleSignOut} className="nav-text">
              Logout
            </span>
          </Menu.Item>
        </Menu>
      );
    });


    return (
      <div>

        <NavBar
          mode="light"
          //   style={{ position: 'absolute',zIndex: 1000}}
          leftContent={[
            <div key={1} onClick={this.onOpenChange}>
              {/* <Icon
                type="menu-fold"
                style={{ fontSize: '20px', color: 'black' }}
              /> */}
            </div>,
            <div
              style={{ position: 'absolute', width: '30%' }}
              key={2}
            >
              <Link to="/">
                <img
                  src="https://btoc-ecom-prod.s3.amazonaws.com/24e21ac4-3668-4b2b-bb93-5491f00cc980/HTMLPage/recaho2.png"
                  style={{ width: '150px', height: '35px', marginLeft: "5%" }}
                />
              </Link>
            </div>

          ]}
          rightContent={[
            <div style={{display:"flex",justifyContent:"end"}}>
              <div>
                <SyncOutlined style={{ marginTop: '5px', marginRight: '20px', cursor: 'pointer', fontSize: "20px" }} onClick={this.syncData} />
              </div> 
            <div key={"sound"}>
              <Tooltip title="Mute/Unmute Notification Sound">
                { this.state.audioEnabled ?  <img
                 onClick={(x) => {
                  this.setSoundOff()
                }}
               style={{ marginTop: '-5px', marginRight: '20px', cursor: 'pointer' }} src='/public/Sound.png'  ></img> :
               
               <img onClick={(x) => {
                this.setSoundOn()
             }}
             style={{ marginTop: '-5px', marginRight: '20px', cursor: 'pointer' }} src='/public/Mute.png'  ></img>
               
               }
               
              </Tooltip>
            </div>
            </div>,

            <div key={1} onClick={this.onOpenChange}>

              {/* <Icon
                type="menu-fold"
                style={{ fontSize: '20px', color: 'black' }}
              /> */}
            </div>,
            <div key={2}>
              <LogoutOutlined onClick={this.handleSignOut} style={{ color: 'black', fontSize: '20px' }} />
            </div>

          ]}
        />

        <Drawer
          className="admin-drawer"
          style={{
            minHeight: document.documentElement.clientHeight,
            marginTop: '14%'
          }}
          // enableDragHandle
          contentStyle={{
            textAlign: 'center',
            overflow: 'auto',
            minHeight: document.documentElement.clientHeight,
            padding: '10px'
          }}
          sidebar={sidebar}
          open={this.state.collapsed}
          onOpenChange={this.onOpenChange}
          sidebarStyle={{ background: '#001529', zIndex: '5' }}
        >
          {children}
        </Drawer>
      </div>
    );
  }
}

export default MLayout;
