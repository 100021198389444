import gql from 'graphql-tag';

export default gql`
query getDiningTableBySite($nextToken: String){
    getDiningTableBySite(nextToken: $nextToken){
     items{
       id
       siteId
       typeValue
       tableNo
       enabled
       tableName
       capacity
       tableStatus
       blockedBy
       attachedArea
       attachedMenu
      createdAt
      updatedAt
     }
     nextToken
   }
 }
`
