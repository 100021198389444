import gql from "graphql-tag";
export default gql`
query getServicesBySiteIdPaginatedV2($siteId: String! $limit: Int, $nextToken: String){
    getServicesBySiteIdPaginatedV2(siteId: $siteId limit: $limit nextToken: $nextToken){
      items{
      serviceName
      slugName
      serviceStatus
      serviceDes
      attachedCat
      goodInventory
      badInventory
      metaKeyword
      tax
      taxType
      itemType
      tags
      displayOrder
      masterProdId
      attachedAddonCat
      pmInventory
      purchaseItemRef
      availableInventory
      isInventory
      station
      addons{
        categoryName
         rule
         isRequired
         customNum
         itemIds
      }
      serviceRates{
        serviceType
        rate
        listRate
        status
      }
      image {
        bucket
        region
        key
      }
    
      }, 
      nextToken
    }
  }`;
//    {
//    "siteId": "58496ae2-1ed7-4888-a168-1ba69a7a61b9"
//  }
