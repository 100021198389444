import React from 'react'
import {Button , Row, Col } from "antd";
import unslugify from "unslugify";
import moment from 'moment'

export default function CancelKotNotify(props) {
    // console.log("Props::",props)
    const {Data,TableData} = props

    let tableName = ""

    TableData && TableData.length > 0 && TableData.map((item) => {
        if (item.typeValue == Data.order.tableNo) {
            tableName = item.tableName
        }
    })

    function showMenuName(s){

        let name = ''
         if(s.subOrderFrom){
              name = name + unslugify(s.subOrderFrom).toUpperCase()
              
              if(s.orderSource){
                name = name + " (" + unslugify(s.orderSource).toUpperCase() + " )"
              }
         }else{
            name = unslugify(s.orderFrom).toUpperCase()
         }
           
         return name
       
    }

  return (
    <div className="kitchenCardForCancelNotification" >
    <Row >
      <Col span={6}>
      <img style={{ marginTop: '-15px' ,height:"50px", marginRight:"20px" , zIndex:4}} src='/public/cancelNotification.png'   ></img>
      </Col>
      <Col span={18}>
        <h3>EDITED KOT CANCELLED ITEM </h3>
      </Col>
    </Row>
  
     <Row>

          {/* <Col span={Data.order.orderFrom == 'dine-in' ? 6 : 4} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <p className="kitcheNames" style={{ fontSize: '17px' }}>Recaho</p>

          </Col> */}
          <Col span={ 24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <div style={{ display:'flex', justifyContent:"center" }}>
                  <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                      <Button
                          style={{ borderRadius: '5px', background: Data.order.orderFrom == 'dine-in' ? '#1b34b1' : Data.order.orderFrom == 'online-menu' ? '#4e45a0' : '#f36b0a', height: '30px' }}>
                          <span style={{ color: 'white',  }}>{showMenuName(Data.order)}</span></Button>
                  </p>
                  { Data.order.orderFrom == 'dine-in' &&
                      <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                          <Button style={{ borderRadius: '5px', background: '#27cfa0', height: '30px' }}><span style={{ color: 'white' }}>
                              {/* <span className="kitcheNames" style={{ color: 'white' }}>Table:</span> {s.tableNo ? s.tableNo.split('::')[1] : ''} */}
                              <span className="kitcheNames" style={{ color: 'white' }}>{tableName}</span>
                          </span></Button>
                      </p>
                  }
                  <p style={{ marginBottom: '0px', marginRight: '15px' }}>
                      <Button style={{ borderRadius: '5px', background: '#9db519', height: '30px' }}><span style={{ color: 'white' }}>
                          <span className="kitcheNames" style={{ color: 'white' }}>ITEMS:</span> {Data.order.totalCount} 
                      </span></Button>
                  </p>
              </div>
          </Col>
          
      </Row>

      {/* <div style={{display:'flex', marginTop:"10px"}}>
         <div style={{marginRight:"10px"}}>
         <p className="kitcheNames" style={{ textAlign: 'left' }}>ORDER ID: {Data.order.orderId}</p>
         </div>

         <div style={{marginRight:"10px"}}>
         <p className="kitcheNames" style={{ textAlign: 'left' }}>KOT NO: {Data.order.kotId}</p>
         </div>

         <div style={{marginRight:"10px"}}>
         <p className="kitcheNames" style={{ textAlign: 'left' }}>Token NO: {Data.order.tokenId}</p>
         </div>

         <div>
         <p className="kitcheNames" style={{ textAlign: 'left' }}>{Data.order.timestamp && moment.unix(Data.order.timestamp).format('DD-MM-YYYY hh:mm a')}</p>
         </div>

      </div> */}

      <Row  style={{marginTop:"10px"}}>
          <Col xs={8} sm={8} md={6} lg={6}>
            <p className="kitcheNames" style={{ textAlign: 'left' }}>ORDER ID: {Data.order.orderId}</p>
          </Col>
          <Col xs={6} sm={6} md={5} lg={5}>
          <p className="kitcheNames" style={{ textAlign: 'left' }}>KOT NO: {Data.order.kotId}</p>
          </Col>
          <Col xs={6} sm={6} md={5} lg={5}>
          <p className="kitcheNames" style={{ textAlign: 'left' }}>Token NO: {Data.order.tokenId}</p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
          <p className="kitcheNames" style={{ textAlign: 'left' }}>{Data.order.timestamp && moment.unix(Data.order.timestamp).format('DD-MM-YYYY hh:mm a')}</p>
          </Col>
      </Row>
      <hr style={{ marginTop: '10px', marginBottom: '10px' }} />

    
      {/* <div>
          <p className="kitcheNames" style={{ textAlign: 'left', marginTop: "10px", fontWeight:"bold" }}>UPDATED KOT</p>

          <div style={{ marginTop: "5px" }}>
          {Data.currentlyCanceledItem &&  Data.currentlyCanceledItem.length > 0 && Data.currentlyCanceledItem.map((item)=>{
                  
              return (
                  <Row>
                      <Col span={10}>
                          <p className="kitcheNames" style={{ textAlign: 'left', textDecoration: item.status == "CANCELLED" && "line-through" , color: item.status == "CANCELLED" &&  "red"}}>{item.serviceName}</p>
                      </Col>
                      <Col span={4}>
                          <p className="kitcheNames" style={{ textAlign: 'left', textDecoration: item.status == "CANCELLED" &&  "line-through" ,color: item.status == "CANCELLED" &&  "red"}}>Qty: {item.qty}</p>
                      </Col>

                      {item.rejectReason &&
                      <Col span={10}>
                          <p className="kitcheNames" style={{ textAlign: 'left', color:"red" }}>{item.rejectReason} </p>
                      </Col>
                       }
                  </Row>)

          })}
              
          </div>

      </div> */}

      <div>
          <p className="kitcheNames" style={{ textAlign: 'center', marginTop: "10px", fontSize:"16px", color:"#57ad10" }}>UPDATED KOT</p>

          <div style={{ marginTop: "5px" }}>
                      
            {Data.updatedList && Data.updatedList.orderList && Data.updatedList.orderList.length > 0 && Data.updatedList.orderList.map((suborder)=>{
                    return suborder.services.map((service)=>{
                        if(service.status != "COMPLETED" && service.status != "PREPARED" ){
                        return(
                            <Row style={{marginBottom:"10px"}}>
                                <Col span={8}>
                                    <p className="kitcheNames" style={{ textAlign: 'left', marginBottom: "5px", textDecoration: service.status == "CANCELLED" && "line-through", color: service.status == "CANCELLED" && "red" }}>{service.serviceName}</p>
                                    {service.addons &&
                                                    <p style={{ marginBottom: '0px',textDecoration: service.status == "CANCELLED"  && "line-through",color: service.status == "CANCELLED" && "red"}}>(
                                                                                        {service.addons && service.addons.map((add, i) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <span>{add.serviceName}</span>
                                                                                                                            {service.addons.length > i + 1 && <span>,&nbsp;</span>}
                                                                                                                        </>

                                                                                                                    )
                                                                                                                })
                                                                                                                }
                                                                                                                )
                                                                                                            </p>
                                                                                                        }
                                                                                                        {service.instruction && service.instruction != '' && <p style={{ color: 'red', fontWeight: 600, marginTop: '-5px', marginBottom: '0px', fontSize: '13px' }}>{`(${service.instruction})`}</p>}
                                </Col>
                                <Col span={4}>
                                    <p className="kitcheNames" style={{ textAlign: 'right', marginBottom: "5px", marginRight:"15px", textDecoration: service.status == "CANCELLED" && "line-through", color: service.status == "CANCELLED" && "red" }}>Qty: {service.qty}</p>
                                </Col>
                                {service.rejectReason &&
                                    <Col span={12}>
                                        <p className="kitcheNames" style={{ textAlign: 'left', color: "red" }}>{service.rejectReason} </p>
                                    </Col>
                                }
                            {/* <Col span={10}>
                                <p  className="kitcheNames" style={{ textAlign: 'left',  marginBottom:"5px", textDecoration: service.status == "CANCELLED" && "line-through"}}>{service.status}</p>
                            </Col> */}
                        </Row>
                        )
                        }
                     })
            })}

                   <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                           <Button style={{ backgroundColor: "#57ad10", color: "white", width:"20%" }} onClick={()=>{props.handleCancelNotificationModal()}} > OK </Button>
                    </div>
              
          </div>

      </div>
    </div>
  )
}
