import React from 'react'

import { Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
  const { tokens } = useTheme();

  return (
      <div style={{textAlign:'center'}}>
        <Image
            alt="logo"
            //   src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
            src="https://btoc-ecom-prod.s3.amazonaws.com/24e21ac4-3668-4b2b-bb93-5491f00cc980/HTMLPage/recaho2.png"
            padding={tokens.space.medium}
            maxWidth="60%"
        />
      </div>
  );
}
