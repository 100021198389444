import React from 'react'
import { Row, Col, Button, Spin,Select } from "antd";
import { Auth } from "aws-amplify";
import { withRouter, Link } from "react-router-dom";
import App from '../../../src/components/kitchenRoleApp/kitchenRoleMain'
import getAssignedStations from '../../queries/getAssignedStations'
// import { withApollo } from "react-apollo";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { graphql, withApollo } from '@apollo/client/react/hoc';
const FormItem = Form.Item;
const stations = ['Kitchen', 'Bar']

class SelectStation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            station: '',
            siteId:'',
            userId:'',
            loadingStation:false,
            stations:[],
            platform:'mobile'
        }
        this.UserId()
    }

    UserId = async () => {
        await Auth.currentUserInfo()
        .then((data) => {
            //  console.log('data SiteID::',data.attributes['custom:siteId'])
            this.setState({
                userId:data.username,
                siteId: data.attributes['custom:siteId']
            })
        })
        .catch(err => console.log(err));
    }

    componentDidMount=()=>{
        this.getStations()
        if (window.screen.width >= 700) {
            //   1280
            this.setState({
                platform: 'desktop'
            })
        } else {
            this.setState({
                platform: 'mobile'
            })
        }
    }

    loadingStation = () => {
        this.setState({
            loadingStation: !this.state.loadingStation
        })
    }

    getStations=()=>{
        this.loadingStation()
        this.props.client.query({
            query: getAssignedStations,
            fetchPolicy: 'network-only',
            variables: {
                userId: this.props.userId
            }
        })
            .then(({ data }) => {
                //  console.log(' stationS data::',data)
                if (data.getAssignedStations != null) {

                    let station = data.getAssignedStations && data.getAssignedStations.filter((st)=>{
                         if(st.enabled === true){
                            return st
                         }
                    })

                    // console.log("Filtered Station::",station)

                    this.setState({
                        stations: station ,
                        loadingStation:false,
                        station: station && station.length> 0 ? station[0].typeValue : ''
                        // station:'kitchen'
                    })
                   
                    if(station && station.length == 1 ){
                    //  console.log("inside one station:::", data.getAssignedStations[0].typeValue)

                    let filt = station && station.filter((item)=>{
                        if(item.typeValue == station[0].typeValue){
                            return item
                        }
                   })
            
                //    console.log("filt:::",filt[0].trackerConfig)
                   let trackerConfig = filt[0].trackerConfig

                    this.props.history.push('/orders', {
                        station: station.length> 0 ? station[0].typeValue : '',
                        stationDetails: filt[0],
                        siteId:this.state.siteId,
                        trackerConfig: trackerConfig 
                     })
                    }  
                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${JSON.stringify(err, null, 2)}`)
            })
    }

    submitStation=()=>{
        this.props.history.push('/orders', {
           station:this.state.station,
           siteId:this.state.siteId
          
        })
    }

    submitStationV2 = (station) =>{
        // console.log("submitStationV2:::",this.state.stations)
        // console.log("station V::",station)

        let filt = this.state.stations && this.state.stations.filter((item)=>{
            if(item.typeValue == station){
                return item
            }
       })

    //    console.log("filt:::",filt[0].trackerConfig)
       let trackerConfig = filt[0].trackerConfig
        // return
        this.props.history.push('/orders', {
            station: station ,
            stationDetails: filt[0],
            siteId:this.state.siteId,
            trackerConfig:trackerConfig 
         })
    } 

    changeStation = (e) => {
        this.setState({
            station:e
        })
    }

    render() {
        // let data = this.state.dataSource
        // console.log('this.state.stations::',this.state.siteId)
        //  console.log("this.state::",this.state)
        return (
            <App>
               { this.state.loadingStation ? <Spin/>
                :
                this.state.stations.length > 0 ?
                 <>
                <div style={{marginTop:"-15px"}}>
                      <h1 style={{textAlign:"center"}}> Select Station</h1> 
                </div>
                <Row gutter={16}>
                     {
                         this.state.stations.length > 0 && this.state.stations.map((item)=>{
                            
                              return(
                                <Col  xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} >
                         { this.state.platform == 'mobile' ?  <div className='stations' style={{ width: "auto",marginBottom:"20px",color:"black",cursor:"pointer", minHeight:"100px" }} onClick={()=>{this.submitStationV2(item.typeValue)}} >
                               
                               <p style={{marginTop:"20px",fontSize:"19px",textAlign:"center"}}>{item.stationName}</p>
                            
                            </div>  :  <div className='stations' style={{ width: "auto",marginBottom:"20px",color:"black",cursor:"pointer", minHeight:"150px" }} onClick={()=>{this.submitStationV2(item.typeValue)}} >
                               
                               <p style={{fontSize:"19px",textAlign:"center",marginTop:"40px"}}>{item.stationName}</p>
                            
                            </div>
                                                               
                         }
                       

                    </Col>
                               
                              )
                        })
                     }
                    
                </Row>  

                </>  : <>
                       <div className='stations' style={{textAlign:"center", marginTop:"10%", }}>
                             <h2 style={{color:'red'}} >No Station Available Please Contact Admin To Add Station</h2>
                       </div>               
                </>    
                  
               
    }

            </App>
        )
    }
}
const wrappSelectStation = Form.create({ name: "JBooking_form" })(SelectStation);

export default withRouter(withApollo(wrappSelectStation))