import gql from 'graphql-tag'
export default gql `
query{
    getRecahoAppVersion{
        domain
        typeValue
        version
        appVersion
        staffVersion
    }
}`