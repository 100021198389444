import gql from 'graphql-tag'

export default gql`
query getServiceTypeSettingInBulk( $typeValues: [String!]!){
    getServiceTypeSettingInBulk(typeValues: $typeValues){
    domain
    typeValue
    siteId
    quantityReq
    showQuickButton
    isTurboOrder
    isKotAllowed
    isCustomItem
    cancelResons
    whatsAppNumber
    diningArea
    regardsName
    futureOrderEnabled
    taxMethod
    orderButtonColor
    minOrderValue
    showPaymentOption
    isCustomerSegment
    customCharges{
      customChargeName
      customChargeList{
        name
        rate
        chargeType
        tax
        defaultSelcted
      }
    }
    customFeilds{
      customType
      customName
      customValue
      customListValue
      menuAttached
      required
      availability
      length
    }
    availableFeilds{
      
      availableType
      displayName
      display
      menuAttached
      required
    }
    formSchemas{
      id
      type
      customType
      customName
      customValue
      customListValue
      required
      display
      availability
      length
      isVerification
      verificationFormat
      displayOrder
      groupBy
      action
      min
      max
    }
    orderButtons
    oneClickButtons
    parcelBtnLabel
    payButtons
    storeStatus
    # minMax
  }
}




`


// # {
  // #   "typeValue": "dine-in::setting"
    
  // # }
  