// import '../../assets/custom.min.css';
import 'antd-mobile/dist/antd-mobile.min.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutOutlined, UserOutlined,SyncOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, Popover, Row, Col, Tooltip } from 'antd';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router';
import MLayout from "./mob/mobMain";
import MediaQuery from 'react-responsive';
import { AndroidPostMsg } from '../../utils/AndroidPostMsg';

// Amplify.configure(aws_exports);

const { Header, Sider, Content } = Layout;
const text = <span>Admin</span>;
const { SubMenu } = Menu;

class JApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      domain: '',
      activemenuKey: '',
      defaultOpen: '',
      openKeys: [],
      audioEnabled: localStorage.hasOwnProperty('mute') ? localStorage.getItem('mute') == 'true' : true
    };
  }

  componentDidMount(){
    if (!localStorage.hasOwnProperty('mute')) {
      // Key is not present in local storage, set it
      localStorage.setItem('mute', JSON.stringify(true))
    }
    
  }

  state = {
    collapsed: false
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleSignOut = () => {
    localStorage.removeItem('inventory')
    localStorage.removeItem('siteOp')
    localStorage.removeItem("siteId")
    localStorage.removeItem("role")
    Auth.signOut()
      .then(() => {
        window.location = window.location.origin;
        // window.location.reload(true)
      })
      .catch(err => console.log(err));
  };

  static showMenuSelected(url, header) {
    let pathArr = url.split('/').filter(pathVal => pathVal != '');

    const pathName = pathArr[0];
    let activeKey = '0';
    let defaultOpen = '';
    if (pathArr.length != 0 && pathArr == 'jewelStaticPage') {
      header = '4'
    }
    // console.log(pathName)
    switch (pathName) {
      case undefined:
        activeKey = 'Approval Inbox';
        break;

      case '':
        activeKey = 'Approval Inbox';
        break;
      case 'editgranite':
        activeKey = 'Approval Inbox';
        break;

      default:
        activeKey = 'Approval Inbox';
    }
    return {
      activeKey,
      defaultOpen
    };
  }
  static getDerivedStateFromProps(nextProps, nextState) {

    const getActiveMenuId = JApp.showMenuSelected(nextProps.match.url, nextProps.header);
    return {
      activemenuKey: getActiveMenuId.activeKey,
      defaultOpen: getActiveMenuId.defaultOpen
    };
  }
  
  setSoundOff = () =>{
    AndroidPostMsg("STOP_BELL")
    const audioEl = document.getElementsByClassName("audio-element")[0]
    if(audioEl){
      // AndroidPostMsg("STOP_BELL")
        audioEl.pause()
    }

    localStorage.setItem('mute', JSON.stringify(false))
    this.setState({
      audioEnabled: false
    })
  }

  setSoundOn = () =>{
    localStorage.setItem('mute', JSON.stringify(true))
    this.setState({
      audioEnabled: true
    })
  }

  syncData = () => {
    console.log("inside SyncData::::")
    location.reload(true);
  }

  // reloadPage(){
  //   window.location.reload()
  // }

  // millisToMinutesAndSeconds(millis) {
  //   var minutes = Math.floor(millis / 60000);
  //   var seconds = ((millis % 60000) / 1000).toFixed(0);
  //   return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  // }

  render() {
    // console.log('kitchen role main')
    const { authorized, children } = this.props;
    const { activemenuKey } = this.state;
    const inventory = localStorage.getItem('inventory')
    const siteOption = localStorage.getItem('siteOp')
    return (
      <MediaQuery minDeviceWidth={700}>
        {matches => {
          if (matches) {
            return (
              <Layout style={{ height: '100vh', background: '#001529' }}>
                <Sider
                  trigger={null}
                  collapsible
                  width={210}
                  collapsed={this.state.collapsed}
                  style={{ height: '100vh', background: '#001529', display: 'none' }}
                >
                  <div
                    className="logo"
                    style={{
                      height: '32px',
                      background: '#001529',
                      margin: '16px'
                    }}
                  >
                    {/* {this.state.collapsed ? (
                      <img
                        src="https://btoc-ecom-prod.s3.amazonaws.com/a78a56fb-5492-47e0-92ab-4284202c5556/HTMLPage/logo.png"
                        className="header-logo"
                        style={{ width: '30px' }}
                      />
                    ) : ( */}
                    <img
                      src="https://btoc-ecom-prod.s3.amazonaws.com/24e21ac4-3668-4b2b-bb93-5491f00cc980/HTMLPage/recaho2.png"
                      style={{ width: '150px', height: '35px' }}
                    />
                    {/* )} */}
                  </div>

                  <Menu theme="dark" mode="inline" selectedKeys={[activemenuKey]} defaultOpenKeys={[this.state.defaultOpen]}>



                    {/* <Menu.Item key="Approval Inbox">
                        <Link to="/">
                          <Icon type="profile" />
                          <span className="nav-text">Approval Inbox</span>
                        </Link>
                      </Menu.Item> */}

                    <Menu.Item key="My Tables">
                      <Link to="/">
                        <UserOutlined />
                        <span className="nav-text">Orders</span>
                      </Link>
                    </Menu.Item>


                    <Menu.Item key="2">
                      <LogoutOutlined />
                      <span onClick={this.handleSignOut} className="nav-text">
                        Logout
                      </span>
                    </Menu.Item>
                  </Menu>
                </Sider>
                <Layout style={{ background: '#fff', height: '100vh' }}>
                  <Header
                    style={{
                      position: 'fixed',
                      padding: 0,
                      zIndex: 1000,
                      width: '100%',
                      background: '#fff',
                      borderBottom: '12px solid #f0f2f5'
                    }}
                  >

                    <Row gutter={16}>
                      <Col style={{ left: "1%" }} span={12}>
                        <img
                          src="https://btoc-ecom-prod.s3.amazonaws.com/24e21ac4-3668-4b2b-bb93-5491f00cc980/HTMLPage/recaho2.png"
                          style={{ width: '150px', height: '35px' }}
                        />
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        <Row>
                        <Col span={20}>
                          <div style={{display:"flex", justifyContent:"end"}}>
                              <div>
                                <SyncOutlined style={{ marginTop: '-5px', marginRight: '20px', cursor: 'pointer', fontSize:"20px" }} onClick={this.syncData} />
                              </div>
                              <div key={"sound"}>
                                <Tooltip title="Mute/Unmute Notification Sound">
                                  {this.state.audioEnabled ? <img onClick={(x) => {
                                    this.setSoundOff()
                                  }}
                                    style={{ marginTop: '-5px', marginRight: '20px', cursor: 'pointer' }} src='/public/Sound.png'  ></img> :

                                    <img onClick={(x) => {
                                      this.setSoundOn()
                                    }}
                                      style={{ marginTop: '-5px', marginRight: '20px', cursor: 'pointer' }} src='/public/Mute.png'  ></img>

                                  }

                                </Tooltip>
                              </div>
                          </div>
                        </Col>
                        <Col >
                          <LogoutOutlined onClick={this.handleSignOut} style={{ fontSize: '22px', marginRight: '10px' }} />
                        </Col>  
                        </Row>
                      
                      </Col>
                      <Col span={18}>
                        <Row type="flex">
                          <Col span={22}>
                            <h2>{this.props.header}</h2>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={18}>
                        <Row type="flex">
                          <Col span={22}>
                            <h2>{this.props.header}</h2>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Header>

                  <Content
                    style={{
                      margin: '50px 16px',
                      padding: 24,
                      background: '#fff'
                    }}
                  >
                    {children}
                    {/* {authorized && children}
                    {!authorized && <div>Not Authorized</div>} */}
                  </Content>
                </Layout>
              </Layout>
            );
          } else {
            return (
              <div>
                <MLayout >
                  {children}
                </MLayout>
              </div>
            )
          }
        }}
      </MediaQuery>
    );
  }
}

export default withRouter(JApp);
