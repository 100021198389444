import getServiceTypeSettingInBulk1 from "../queries/getServiceTypeSettingInBulk";
export const getServiceTypeSettingInBulk = (menu, client) => {
    return new Promise((resolve, reject) => {
        client.query({
            query: getServiceTypeSettingInBulk1,
            fetchPolicy: 'network-only',
            variables: {
                typeValues: menu

            }
        })
            .then(({ data }) => {
             
                resolve(data.getServiceTypeSettingInBulk)
            })
            .catch(err => {
                console.log(`Error : ${JSON.stringify(err)}`)

            })
    })
}