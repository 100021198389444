import gql from 'graphql-tag'
export default gql `
query getPlatformServiceBySlugName($siteId: String!  $slugServiceName: String!){
    getPlatformServiceBySlugName(siteId: $siteId  slugServiceName: $slugServiceName){
      siteId
      serviceId
      serviceName
      status
  
    }
  }
  `