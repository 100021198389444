import gql from 'graphql-tag';

export default gql`
query getSiteSubRoleByUserName($userName: String!){
    getSiteSubRoleByUserName(userName:$userName){
      id
      siteId
      username
      userRole
      emailId
      mobile
      name
      assignedTables
      enabled
      attachedMenus
      attachedCounters
      isCaptain
      discountCap{
        discountType
        discountValue
      }
      permissions{
        name
        operations
      }
    }
  }
`