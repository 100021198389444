import React, { useEffect, useState } from 'react'
import { Spin ,Table,Button,message } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import updateTableOrderItemStatusInBulk from '../../mutations/updateTableOrderItemStatusInBulk'
import getSiteOrderByItemStatus from '../../queries/getSiteOrderByItemStatus';
import tableOrderUpdatedV2 from '../../subscription/tableOrderUpdatedV2';

class ItemCount extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countData: [],
            loading: true,
            dataPayload: null,
            readyAllLoading: false,
            selectedItem: null,
            MainDataSource: []
        };

    }

    componentDidMount() {
             this.getSiteOrder()
             this.tableOrderUpdatedV2ItemCount()
    }

    componentWillUnmount() {

    }

    tableOrderUpdatedV2ItemCount = () =>{
        let that = this
        let retryAttempts = 0; 
        this.subscriptionV2 = this.props.client.subscribe({
            query: tableOrderUpdatedV2,
            variables:{
                siteId: this.props.siteId
            }
        }).subscribe({
            next: ({data}) => {
            //    console.log("restaurantData tableOrderUpdatedV2 ::",data.tableOrderUpdatedV2)
              retryAttempts = 0;
              let order = data.tableOrderUpdatedV2


                // if (filteredArr) {
                     this.createDataForBulkAcceptUpdated(order)
                     this.filterRecordUpdated(order)
                // }
              
             
            },
            error: (err) => {
                retryAttempts++;
                console.log('table updated v2 error', err);
                const maxRetryAttempts = 5;

                if (retryAttempts <= maxRetryAttempts) {
                  const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
                  setTimeout(this.tableOrderUpdatedV2ItemCount, delay);

                } else {
                  console.log('Max retry attempts reached. Stopping retries.');
                }
            }
        });
    }

     getSiteOrder() {
        //this.loadingSpin()
        this.props.client.query({
            query: getSiteOrderByItemStatus,
            fetchPolicy: 'network-only',
            variables: {
                station: this.props.station,
                // station:'kitchen',
                status: ['IN_PROCESS','KOT_GENERATED'],
                skip: 0,
                limit: 200
                // tableNo:'1'

            }
        })
            .then(({ data }) => {
                //  console.log('getSiteOrderByItemStatus::::', data)
                if (data.getSiteOrderByItemStatus != null) {
                   
                    const arr = data.getSiteOrderByItemStatus;

                    const filteredArr = arr.filter(order => order.orderList.some(item => item.services.length > 0));
                    
                   this.createDataForBulkAccept(filteredArr)

                    this.filterRecord(filteredArr)

                }
            })
            .catch(err => {
                console.log(`Catch: handleFormSubmit: error: ${err}`)
            })
    }

    createDataForBulkAccept(arr) {

        this.setState({MainDataSource:arr})

        let payloadArray = [];

        arr.forEach(order => {
            if (order.orderList && order.orderList.length > 0) {
                order.orderList.forEach(orderItem => {
                    if (orderItem.services && orderItem.services.length > 0) {
                        orderItem.services.forEach(service => {
                            const itemName = service.serviceName; // Assuming serviceName is the item name
                            const itemPayload = {
                                subOrderId: orderItem.subOrderId,
                                serviceId: service.uId,
                                status: service.status == 'KOT_GENERATED'? 'IN_PROCESS' : 'PREPARED'
                            };

                            let existingPayload = payloadArray.find(item => item[itemName]);
                            if (!existingPayload) {
                                existingPayload = {};
                                existingPayload[itemName] = [];
                                payloadArray.push(existingPayload);
                            }

                            const existingItemArray = existingPayload[itemName];

                            const existingItem = existingItemArray.find(item => item.id === order.id);

                            if (existingItem) {
                                existingItem.items.push({ ...itemPayload });
                            } else {
                                existingItemArray.push({
                                    id: order.id,
                                    items: [{ ...itemPayload }]
                                });
                            }
                        });
                    }
                });
            }
        });

        // console.log("Payload Create Data::", payloadArray);


       this.setState({dataPayload: payloadArray})

    }


    createDataForBulkAcceptUpdated(order){
    
            let DataSourceDummy = JSON.parse(JSON.stringify(this.state.MainDataSource))
            let DataSource
            if(order.tableOrderStatus == "CLOSED"){
             DataSource = DataSourceDummy.filter((data => data.orderId != order.orderId))
            // console.log("inside createDataForBulkAcceptUpdated Record:::", DataSource)
            }else{
                DataSource = DataSourceDummy
            }
            let index = DataSource && DataSource.findIndex((data)=> data.orderId == order.orderId)
            if(index != -1){
                DataSource[index] = order
            }else{
                if(index == -1 && order.tableOrderStatus != "CLOSED"){
                    DataSource.push(order)
                }
            }
            let payloadArray = [];

        DataSource && DataSource.forEach(order => {
            if (order.orderList && order.orderList.length > 0) {
                order.orderList.forEach(orderItem => {
                    if (orderItem.services && orderItem.services.length > 0) {
                        orderItem.services.forEach(service => {
                           if(service.station == this.props.station && (service.status == 'KOT_GENERATED' || service.status == 'IN_PROCESS' )){
                            const itemName = service.serviceName; 
                            const itemPayload = {
                                subOrderId: orderItem.subOrderId,
                                serviceId: service.uId,
                                status: service.status == 'KOT_GENERATED'? 'IN_PROCESS' : 'PREPARED'
                            };

                            let existingPayload = payloadArray.find(item => item[itemName]);
                            if (!existingPayload) {
                                existingPayload = {};
                                existingPayload[itemName] = [];
                                payloadArray.push(existingPayload);
                            }

                            const existingItemArray = existingPayload[itemName];
                            const existingItem = existingItemArray.find(item => item.id === order.id);

                            if (existingItem) {
                                existingItem.items.push({ ...itemPayload });
                            } else {
                                existingItemArray.push({
                                    id: order.id,
                                    items: [{ ...itemPayload }]
                                });
                            }
                        }
                        });
                    }
                });
            }
        });

        // console.log("Payload Create Data::", payloadArray);


       this.setState({dataPayload: payloadArray})
       
    }

    filterRecordUpdated(order) {
        let orderListDummy = JSON.parse(JSON.stringify(this.state.MainDataSource));
        let orderList
        if(order.tableOrderStatus == "CLOSED"){
          orderList = orderListDummy.filter((data => data.orderId != order.orderId))
            // console.log("inside Filter Record:::", orderList)
        }else{
         orderList = orderListDummy 
        }

        let index = orderList && orderList.findIndex((data)=> data.orderId == order.orderId)
      
        if(index != -1){
            orderList[index] = order
        }else{
            if(index == -1 && order.tableOrderStatus != "CLOSED"){
                orderList.push(order)
            }
        }
        const serviceObj = {};
        // console.log("orderList:::", orderList)
        orderList.forEach((order) => {
            order.orderList.forEach((subOrder) => {
                subOrder.services.forEach((service) => {
                    if(service.station == this.props.station && (service.status == 'KOT_GENERATED' || service.status == 'IN_PROCESS' )){
                    const serviceName = service.serviceName;
                    const qty = service.qty;
                    const status = service.status; // Assuming status is available in your data
    
                    if (!serviceObj.hasOwnProperty(serviceName)) {
                        serviceObj[serviceName] = [];
                    }
    
                    const existingIndex = serviceObj[serviceName].findIndex(item => item.status === status);
                    
                    if (existingIndex !== -1) {
                        // If exists, update the quantity
                        serviceObj[serviceName][existingIndex].quantity += qty;
                    } else {
                        // If doesn't exist, push a new entry
                        serviceObj[serviceName].push({
                            quantity: qty,
                            status: status
                        });
                    }
                  }
                });
            });
        });
    
        // console.log("data from object", serviceObj);
    
        const newArr = [];
    
        for (const [name, entries] of Object.entries(serviceObj)) {
            entries.forEach((entry) => {
                newArr.push({
                    productName: name,
                    quantity: entry.quantity,
                    status: entry.status
                });
            });
        }
    
        //  console.log("final data", newArr);

        this.setState({countData:newArr,loading:false,MainDataSource:orderList})
    
    }
    

     filterRecord(data) {
        var orderList = data;
        const serviceObj = {};
    
        orderList.forEach((order) => {
            order.orderList.forEach((subOrder) => {
                subOrder.services.forEach((service) => {
                    const serviceName = service.serviceName;
                    const qty = service.qty;
                    const status = service.status; // Assuming status is available in your data
    
                    if (!serviceObj.hasOwnProperty(serviceName)) {
                        serviceObj[serviceName] = [];
                    }
    
                    const existingIndex = serviceObj[serviceName].findIndex(item => item.status === status);
                    
                    if (existingIndex !== -1) {
                        // If exists, update the quantity
                        serviceObj[serviceName][existingIndex].quantity += qty;
                    } else {
                        // If doesn't exist, push a new entry
                        serviceObj[serviceName].push({
                            quantity: qty,
                            status: status
                        });
                    }
                });
            });
        });
    
        // console.log("data from object", serviceObj);
    
        const newArr = [];
    
        for (const [name, entries] of Object.entries(serviceObj)) {
            entries.forEach((entry) => {
                newArr.push({
                    productName: name,
                    quantity: entry.quantity,
                    status: entry.status
                });
            });
        }
    
        // console.log("final data", newArr);

        this.setState({countData:newArr,loading:false})
    
    }

    
    callReadyAll(payload){ 
        return new Promise((resolve,reject)=>{
          this.props.client.mutate({
              mutation: updateTableOrderItemStatusInBulk,
              variables: payload
          }).then(({ data }) => {
              if (data.updateTableOrderItemStatusInBulk) {
                 
                //  console.log("Mutation Success", data.updateTableOrderItemStatusInBulk)
                  resolve(true)
                
              }
          }).catch((err) => {
              resolve(true)
              console.log(`Catch: updateTableOrderItemStatusInBulk:`,err)
          })
        })
     
    }

    async readyAll(data) {
    
        let BulkOperationData = JSON.parse(JSON.stringify((this.state.dataPayload)))

        let countDataCopy = JSON.parse(JSON.stringify(this.state.countData))
    
           let find =  BulkOperationData.filter((item)=>{
            if(item[data.productName] ){
                return item
            }
        })
    
        //  return
       
        let final = find[0][data.productName]
        let TransformBulkData = JSON.parse(JSON.stringify(final))
        // console.log("status:::",data.status)
    
      let sendToPayload =  final.filter(order => {
            if(data.status == "KOT_GENERATED"){
            order.items = order.items.filter( service => service.status == 'IN_PROCESS');
            }else{
                order.items = order.items.filter( service => service.status == 'PREPARED'); 
            }
            return order.items.length > 0;
        });
    
        // TransformBulkData = TransformBulkData.filter(order => {
        //     if(data.status == "KOT_GENERATED"){
        //     order.items = order.items.filter( service => service.status != 'IN_PROCESS');
        //     }else{
        //         order.items = order.items.filter( service => service.status != 'PREPARED'); 
        //     }
        //     return order.items.length > 0;
        // });
    
    //     let foundObjectIndex = BulkOperationData.findIndex(obj => Object.keys(obj)[0] === data.productName);  
    //     console.log("foundObject::",foundObjectIndex)
    
    
    //     let CopyOfBulkData = BulkOperationData
    
    //   if (TransformBulkData.length === 0) {
    //     CopyOfBulkData.splice(foundObjectIndex, 1);
    //   } else {
    //     CopyOfBulkData[foundObjectIndex][Object.keys(CopyOfBulkData[foundObjectIndex])[0]] = TransformBulkData;
    //   }
    
        //  console.log("filteredArrT final:::",sendToPayload)
        //  console.log("TransformBulkData::",TransformBulkData)
        //  console.log("CopyOfBulkData:::",CopyOfBulkData)
        //  return
         
        let promise = [];
        try
        {
         this.setState({readyAllLoading:true,selectedItem:data}) 
        for(let i=0; i<sendToPayload.length; i++){
            //   console.log(final[i])
          promise.push(this.callReadyAll(sendToPayload[i]))  
        }
    
        let response = await Promise.all(promise)
     
        if(response.length){
        // let FilterData =  countDataCopy.filter((item)=>{
        //       if(item.productName == data.productName && item.status == data.status){
        //         return false
        //       }else{
        //          return item
        //       }
        //   })
        //   console.log("FilterData Mutation::",FilterData)
          message.success("Updated  Successfully")
          this.setState({readyAllLoading:false,selectedItem:null})
          
        }
       }catch(err){
              console.log("err",err)
        }
        
    
      }
    

    render() {

        const columns = [
            {
              title: <p style={{ margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>MENU ITEM</p>,
              dataIndex: 'productName',
              width:"40%",
              render: text => <p style={{ margin:"0", marginLeft:"2rem", fontSize:"15px",fontWeight:"bold"}}>{text}</p>,
            },
            {
              title: <p style={{margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>QUANTITY</p>,
              dataIndex: 'quantity',
              render: text => <p style={{margin:"0", marginLeft:"2rem", fontSize:"15px",fontWeight:"bold"}}>{text}</p>,
            },
            {
              title: <p style={{margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>Action</p>,
              render: data =>  <Button style={{background: data.status == "KOT_GENERATED" ?'rgb(241, 74, 82)':"#75a85a",color:"white"}} loading={this.state.selectedItem ? this.state.selectedItem.productName == data.productName && this.state.selectedItem.status == data.status : false } onClick={()=>{this.readyAll(data)}} > {data.status == "KOT_GENERATED" ? "Accept All" : "Ready All"}</Button> 
            }
          ];
      
        return (
            <div style={{ backgroundColor: "white" }}>
                {this.state.loading ? <Spin style={{ margin: "0 auto" }} /> : <Table
                    size='small'
                    className='count-table'
                    columns={columns}
                    dataSource={this.state.countData}
                    bordered
                    pagination={false}

                />

                }
            </div>
        );
    }
}

export default withApollo(ItemCount)

// function ItemCount(props) {
//     const [countData, setCountData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [dataPayload,setDataPayload] = useState(null);
//     const [readyAllLoading , setReadyAllLoading] = useState(false) 
//     const [selectedItem, setSelectedItem] = useState(null)

//     console.log('item ciunt::::',dataPayload)

//     useEffect(() => {
//         console.log(" props", props)
//         getSiteOrder()
//         tableOrderUpdatedV2ItemCountSubscriptionCall()
//         // tableOrderUpdatedV2ItemCountSubscriptionCall()
//     }, []);

//     function getSiteOrder() {
//         //this.loadingSpin()
//         props.client.query({
//             query: getSiteOrderByItemStatus,
//             fetchPolicy: 'network-only',
//             variables: {
//                 station: props.station,
//                 // station:'kitchen',
//                 status: ['IN_PROCESS','KOT_GENERATED'],
//                 skip: 0,
//                 limit: 200
//                 // tableNo:'1'

//             }
//         })
//             .then(({ data }) => {
//                  console.log('getSiteOrderByItemStatus::::', data)
//                 if (data.getSiteOrderByItemStatus != null) {
//                    // console.log(data.getSiteOrderByItemStatus)

//                     const arr = data.getSiteOrderByItemStatus;

//                     const filteredArr = arr.filter(order => order.orderList.some(item => item.services.length > 0));
//                     //  console.log(filteredArr)
//                    // CreateDataForBulk(arr)

//                    createDataForBulkAccept(filteredArr)

//                     filterRecord(filteredArr)

//                     //setCountData(filteredArr)
//                     // this.createData(data.getSiteOrderByItemStatus)
//                     // this.getOrderItemsOnSubscribe(data.getSiteOrderByItemStatus)
//                 }
//             })
//             .catch(err => {
//                 console.log(`Catch: handleFormSubmit: error: ${err}`)
//             })
//     }

//     function tableOrderUpdatedV2ItemCountSubscriptionCall() {
//         let retryAttempts = 0;
//          props.client.subscribe({
//             query: tableOrderUpdatedV2,
//             variables: {
//                 siteId: props.siteId
//             }
//         }).subscribe({
//             next: ({ data }) => {
//               console.log("ItemCount tableOrderUpdatedV2 ::",data.tableOrderUpdatedV2)
//               console.log("dataPayload:::",dataPayload,countData)
//               return
//               let order = data.tableOrderUpdatedV2
//                 retryAttempts = 0;
               
//                 order.orderList && order.orderList.map((order)=>{
//                     order.services = order.services.filter((item)=> 
//                     { 
//                         if( (item.status == 'KOT_GENERATED' || item.status == 'IN_PROCESS') && item.station == props.station ){
//                                       return item
//                          }
//                    })
//                 })
                
//                 const filteredArr = order.orderList.some(item => item.services.length > 0);

//                 if(filteredArr){
//                     // createDataForBulkAcceptUpdated(order)
//                 }
                

//             },
//             error: (err) => {
//                 retryAttempts++;
//                 console.log('tableOrderUpdatedV2 error', err);
//                 const maxRetryAttempts = 5;

//                 if (retryAttempts <= maxRetryAttempts) {
//                   // Calculate the exponential backoff delay (adjust as needed)
//                   const delay = Math.pow(2, retryAttempts) * 1000; // Exponential backoff with base 2
        
//                   setTimeout(tableOrderUpdatedV2ItemCountSubscriptionCall, delay);

//                 } else {
//                   console.log('Max retry attempts reached. Stopping retries.');
//                 }
//             }
//         });
//     }


//     function createDataForBulkAcceptUpdated(order){
//         console.log("createDataForBulkAcceptUpdated:::",order)
//         let payloadArray = JSON.parse(JSON.stringify(dataPayload))

//         console.log("Payload Arr::",dataPayload)

//         return

//             // Check if orderList array is present and not empty
//             if (order.orderList && order.orderList.length > 0) {
//                 // Iterate through the order items
//                 order.orderList.forEach(orderItem => {
//                     // Check if services array is present and not empty
//                     if (orderItem.services && orderItem.services.length > 0) {
//                         // Iterate through the services of the order item
//                         orderItem.services.forEach(service => {
//                             // Extract information and add it to the payload
//                             const itemName = service.serviceName; // Assuming serviceName is the item name
//                             const itemPayload = {
//                                 subOrderId: orderItem.subOrderId,
//                                 serviceId: service.uId,
//                                 status: service.status == 'KOT_GENERATED'? 'IN_PROCESS' : 'PREPARED'
//                             };

//                             // Find the existing payload for the item or create a new one
//                             let existingPayload = payloadArray.find(item => item[itemName]);
//                             if (!existingPayload) {
//                                 existingPayload = {};
//                                 existingPayload[itemName] = [];
//                                 payloadArray.push(existingPayload);
//                             }

//                             // Check if an "items" array exists for the item, create it if not
//                             const existingItemArray = existingPayload[itemName];

//                             // Check if an entry already exists for the current order
//                             const existingItem = existingItemArray.find(item => item.id === order.id);

//                             if (existingItem) {
//                                 // Append to existing "items" array without the id field
//                                 existingItem.items.push({ ...itemPayload });
//                             } else {
//                                 // Add a new entry to the "items" array without the id field
//                                 existingItemArray.push({
//                                     id: order.id,
//                                     items: [{ ...itemPayload }]
//                                 });
//                             }
//                         });
//                     }
//                 });
//             }
       

//         // Log the final payload array with readable formatting
//         console.log("createDataForBulkAcceptUpdated Payload::", payloadArray);
//            return
//         setDataPayload(payloadArray)

//     }



//     function createDataForBulkAccept(arr) {

//         let payloadArray = [];

//         // Iterate through the orders
//         arr.forEach(order => {
//             // Check if orderList array is present and not empty
//             if (order.orderList && order.orderList.length > 0) {
//                 // Iterate through the order items
//                 order.orderList.forEach(orderItem => {
//                     // Check if services array is present and not empty
//                     if (orderItem.services && orderItem.services.length > 0) {
//                         // Iterate through the services of the order item
//                         orderItem.services.forEach(service => {
//                             // Extract information and add it to the payload
//                             const itemName = service.serviceName; // Assuming serviceName is the item name
//                             const itemPayload = {
//                                 subOrderId: orderItem.subOrderId,
//                                 serviceId: service.uId,
//                                 status: service.status == 'KOT_GENERATED'? 'IN_PROCESS' : 'PREPARED'
//                             };

//                             // Find the existing payload for the item or create a new one
//                             let existingPayload = payloadArray.find(item => item[itemName]);
//                             if (!existingPayload) {
//                                 existingPayload = {};
//                                 existingPayload[itemName] = [];
//                                 payloadArray.push(existingPayload);
//                             }

//                             // Check if an "items" array exists for the item, create it if not
//                             const existingItemArray = existingPayload[itemName];

//                             // Check if an entry already exists for the current order
//                             const existingItem = existingItemArray.find(item => item.id === order.id);

//                             if (existingItem) {
//                                 // Append to existing "items" array without the id field
//                                 existingItem.items.push({ ...itemPayload });
//                             } else {
//                                 // Add a new entry to the "items" array without the id field
//                                 existingItemArray.push({
//                                     id: order.id,
//                                     items: [{ ...itemPayload }]
//                                 });
//                             }
//                         });
//                     }
//                 });
//             }
//         });

//         // Log the final payload array with readable formatting
//         console.log("Payload Create Data::", payloadArray);


//         setDataPayload(payloadArray)

//     }

//   async  function readyAll(data) {
    
//     let BulkOperationData = JSON.parse(JSON.stringify((dataPayload)))

//        let find =  BulkOperationData.filter((item)=>{
//         if(item[data.productName] ){
//             return item
//         }
//     })

//     //  return
   
//     let final = find[0][data.productName]
//     let TransformBulkData = JSON.parse(JSON.stringify(final))
//     // console.log("status:::",data.status)

//   let sendToPayload =  final.filter(order => {
//         if(data.status == "KOT_GENERATED"){
//         order.items = order.items.filter( service => service.status == 'IN_PROCESS');
//         }else{
//             order.items = order.items.filter( service => service.status == 'PREPARED'); 
//         }
//         return order.items.length > 0;
//     });

//     TransformBulkData = TransformBulkData.filter(order => {
//         if(data.status == "KOT_GENERATED"){
//         order.items = order.items.filter( service => service.status != 'IN_PROCESS');
//         }else{
//             order.items = order.items.filter( service => service.status != 'PREPARED'); 
//         }
//         return order.items.length > 0;
//     });

//     let foundObjectIndex = BulkOperationData.findIndex(obj => Object.keys(obj)[0] === data.productName);  
//     console.log("foundObject::",foundObjectIndex)

//     // Given index

//     let CopyOfBulkData = BulkOperationData

//   if (TransformBulkData.length === 0) {
//     // If arr is empty, remove the object at the specified index from data
//     CopyOfBulkData.splice(foundObjectIndex, 1);
//   } else {
//     // If arr is not empty, update the object at the specified index in the data array
//     CopyOfBulkData[foundObjectIndex][Object.keys(CopyOfBulkData[foundObjectIndex])[0]] = TransformBulkData;
//   }

//     //  console.log("filteredArrT final:::",sendToPayload)
//     //  console.log("TransformBulkData::",TransformBulkData)
//     //  console.log("CopyOfBulkData:::",CopyOfBulkData)
//     //  return
     
//     let promise = [];
//     try{
//         setReadyAllLoading(true)
//         setSelectedItem(data)
//     for(let i=0; i<sendToPayload.length; i++){
//         //   console.log(final[i])
//       promise.push(callReadyAll(sendToPayload[i]))  
//     }

//     let response = await Promise.all(promise)
 
//     if(response.length){
//     let FilterData =   countData.filter((item)=>{
//           if(item.productName == data.productName && item.status == data.status){
//             return false
//           }else{
//              return item
//           }
//       })
//       message.success("Updated  Successfully")
//       setReadyAllLoading(false)   
//       setCountData(FilterData)
//       console.log("CopyOfBulkData Set State::",CopyOfBulkData)
//       setDataPayload(CopyOfBulkData)
//       setSelectedItem(null)
      
//     }
//    }catch(err){
//           console.log("err",err)
//     }
    

//   }

//    function callReadyAll(payload){ console.log("Payload mutation::",payload)
//       return new Promise((resolve,reject)=>{
//         props.client.mutate({
//             mutation: updateTableOrderItemStatusInBulk,
//             variables: payload
//         }).then(({ data }) => {
//             if (data.updateTableOrderItemStatusInBulk) {
               
//                console.log(data.updateTableOrderItemStatusInBulk)
//                 // resolve(true)
//                 // this.createOnUpdate(data.updateTableOrderItemStatusInBulk,this.state.kitchenStatus)
//                 // this.createDataV2(data.updateTableOrderItemStatusInBulk,this.state.kitchenStatus)
//                 resolve(true)
//             }
//         }).catch((err) => {
//             resolve(true)
//             console.log(`Catch: updateTableOrderItemStatusInBulk:`,err)
//         })
//       })
   
//   }

    

//     function filterRecord(data) {
//         var orderList = data;
//         const serviceObj = {};
    
//         orderList.forEach((order) => {
//             order.orderList.forEach((subOrder) => {
//                 subOrder.services.forEach((service) => {
//                     const serviceName = service.serviceName;
//                     const qty = service.qty;
//                     const status = service.status; // Assuming status is available in your data
    
//                     if (!serviceObj.hasOwnProperty(serviceName)) {
//                         serviceObj[serviceName] = [];
//                     }
    
//                     // Check if an entry with the same name and status already exists
//                     const existingIndex = serviceObj[serviceName].findIndex(item => item.status === status);
                    
//                     if (existingIndex !== -1) {
//                         // If exists, update the quantity
//                         serviceObj[serviceName][existingIndex].quantity += qty;
//                     } else {
//                         // If doesn't exist, push a new entry
//                         serviceObj[serviceName].push({
//                             quantity: qty,
//                             status: status
//                         });
//                     }
//                 });
//             });
//         });
    
//         console.log("data from object", serviceObj);
    
//         const newArr = [];
    
//         for (const [name, entries] of Object.entries(serviceObj)) {
//             entries.forEach((entry) => {
//                 newArr.push({
//                     productName: name,
//                     quantity: entry.quantity,
//                     status: entry.status
//                 });
//             });
//         }
    
//         console.log("final data", newArr);
    
//         setCountData(newArr);
//         setLoading(false);
//     }
    
    
    
    

//         const columns = [
//             {
//               title: <p style={{ margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>MENU ITEM</p>,
//               dataIndex: 'productName',
//               width:"40%",
//               render: text => <p style={{ margin:"0", marginLeft:"2rem", fontSize:"15px",fontWeight:"bold"}}>{text}</p>,
//             },
//             {
//               title: <p style={{margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>QUANTITY</p>,
//               dataIndex: 'quantity',
//               render: text => <p style={{margin:"0", marginLeft:"2rem", fontSize:"15px",fontWeight:"bold"}}>{text}</p>,
//             },
//             {
//               title: <p style={{margin:"0", marginLeft:"2rem", fontSize:"18px",fontWeight:"bold",color:"white"}}>Action</p>,
//               render: data =>  <Button style={{background:"#75a85a",color:"white"}} loading={selectedItem ? selectedItem.productName == data.productName && selectedItem.status == data.status : false } onClick={()=>{readyAll(data)}} > {data.status == "KOT_GENERATED" ? "Accept All" : "Ready All"}</Button> 
//             }
//           ];


   
//     return (
//         <>  {console.log("Render In Payload::", dataPayload)}
//         <div style={{backgroundColor:"white"}}>
//             { loading ? <Spin style={{margin: "0 auto"}}/> :  <Table
//              size='small'
//            className='count-table'
//     columns={columns}
//     dataSource={countData}
//     bordered
//     pagination={false}

//   />

//             }
//    </div>
//         </>
//     )
// }