
const getCustomLabels = (customLabels) => {
    let defaultLables ={ Station: "Station",
    Chef: "Chef",
    Area: "Areas",
    Waiter: "Waiter",
    Captain: "Captain",
    Table: "Table",
    KOT: "KOT",
    Table_View: "Table View",
    Manage_Dine_In: "Manage Dine-In"}
    // let defaultLables ={ Station: "Workstation",
    // Chef: "Cook",
    // Area: "Section",
    // Waiter: "Culinarian",
    // Captain: "Captain2",
    // Table: "Service Station",
    // KOT: "Ticket",
    // Table_View: "Table Layout",
    // Manage_Dine_In: "Manage Table"}
    
    // let obj = {}
    if(customLabels && customLabels.length>0){
    
        for(let i=0;i<customLabels.length;i++){
            defaultLables[customLabels[i].defaultName]=customLabels[i].displayName  
        }
    
    }
    return defaultLables
    }
    
    export default getCustomLabels