import gql from 'graphql-tag';

export default gql`
query getAssignedStations( $userId: String!){
    getAssignedStations(userId: $userId){
      id
      domain
      typeValue
      stationName
      enabled
      createdAt
      trackerConfig {
        time
        color
      }
    
    }
}
`
//  {
//       "userId": "sohel"
//      }
    

